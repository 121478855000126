
import {
    PARRAINAGE_MESSAGES_RESET,
    CREATE_PARRAINAGE_FAILED,
    CREATE_PARRAINAGE_REQUEST,
    CREATE_PARRAINAGE_SUCCESS,
    DELETE_PARRAINAGE_FAILED,
    DELETE_PARRAINAGE_REQUEST,
    DELETE_PARRAINAGE_SUCCESS,
    GET_PARRAINAGE_BY_NAME_FAILED,
    GET_PARRAINAGE_BY_NAME_REQUEST,
    GET_PARRAINAGE_BY_NAME_SUCCESS,
    GET_PARRAINAGE_FAILED,
    GET_PARRAINAGE_REQUEST,
    GET_PARRAINAGE_SUCCESS,
    UPDATE_PARRAINAGE_FAILED,
    UPDATE_PARRAINAGE_REQUEST,
    RESET_PARRAINAGE_MESSAGES,
    RESET_PARRAINAGE_MESSAGES_SUCCESS,
    UPDATE_PARRAINAGE_SUCCESS
} from "./actionTypes.js"

export const getParrainageByName = name => ({
    type: GET_PARRAINAGE_BY_NAME_REQUEST,
    payload: name
})


export const getParrainageByNameSuccess = payload => ({
    type: GET_PARRAINAGE_BY_NAME_SUCCESS,
    payload
})


export const getParrainageByNameFailed = payload => ({
    type: GET_PARRAINAGE_BY_NAME_FAILED,
    payload
})




export const getMDMParrainage = (filter) => ({
    type: GET_PARRAINAGE_REQUEST,
    payload:filter
})



export const getMDMParrainageSuccess = payment => ({
    type: GET_PARRAINAGE_SUCCESS,
    payload: payment
})

export const getMDMParrainageFailed = payload => ({
    type: GET_PARRAINAGE_FAILED,
    payload
})

export const createMDMParrainage = Parrainage => ({
    type: CREATE_PARRAINAGE_REQUEST,
    payload: Parrainage
})

export const createMDMParrainageSuccess = Parrainage => ({
    type: CREATE_PARRAINAGE_SUCCESS,
    payload: Parrainage
})

export const createMDMParrainageFailed = payload => ({
    type: CREATE_PARRAINAGE_FAILED,
    payload
})

export const deleteMDMParrainage = id => ({
    type: DELETE_PARRAINAGE_REQUEST,
    payload: id
})

export const deleteMDMParrainageSuccess = id => ({
    type: DELETE_PARRAINAGE_SUCCESS,
    payload: id
})

export const deleteMDMParrainageFailed = id => ({
    type: DELETE_PARRAINAGE_FAILED,
    payload: id
})

export const updateMDMParrainage = (Parrainage )=> ({
    type: UPDATE_PARRAINAGE_REQUEST,
    payload: Parrainage
})

export const updateMDMParrainageSuccess = (Parrainage ) => ({
    type: UPDATE_PARRAINAGE_SUCCESS,
    payload: Parrainage
})

export const updateMDMParrainageFailed = payload => ({
    type: UPDATE_PARRAINAGE_FAILED,
    payload
})

export const resetMDMParrainageMessages = () => ({
    type: PARRAINAGE_MESSAGES_RESET
}
)


export const resetParrainageMessages = () => ({
    type: RESET_PARRAINAGE_MESSAGES,
  })
  
  export const resetParrainageMessagesSuccess = () => ({
    type: RESET_PARRAINAGE_MESSAGES_SUCCESS,
  })

