import {
  createRetailer,
  getRetailers,
  getRetailersByName,
  getRetailerstats,
  getUser,
  updateRetailer,
} from "helpers/api_helper"
import { all, put, takeEvery } from "redux-saga/effects"
// import Repository from "../../repositories/RetailersRepository";
import {
  createRetailerFailed,
  createRetailerSuccess,
  deleteRetailerFailed,
  resetRetailersMessagesSuccess,
  deleteRetailerSuccess,
  getRetailersFailed,
  getRetailersSuccess,
  getRetailersByNameFailed,
  getRetailersByNameSuccess,
  updateRetailerFailed,
  updateRetailerSuccess,
  getRetailersStatsFailed,
  getRetailersStatsSuccess,
} from "./actions"
import { actionTypes } from "./actions"

function* getRetailersSaga({ payload }) {
  try {
    const response = yield getRetailers(payload)
    const { data, ...filters } = response
    yield put(getRetailersSuccess(response))
  } catch (e) {
    console.log("error-----", e)
    yield put(getRetailersFailed(e.response?.status))
  }
}
function* getRetailersStatsSaga() {
  try {
    const response = yield getRetailerstats()
    // console.log("response",response)
    yield put(getRetailersStatsSuccess(response))
  } catch (e) {
    // console.log("error", e.response)
    yield put(getRetailersStatsFailed(e.response?.status))
  }
}

function* getRetailersByIdSaga({ payload }) {
  try {
    //const response = yield getUser(payload);
    yield put(getRetailersByNameSuccess(payload))
  } catch (e) {
    yield put(getRetailersByNameFailed(e.response?.status))
  }
}

function* createRetailerSaga({ payload: retailer }) {
  // console.log("ree", retailer)
  try {
    // const result = yield new Repository().createRetailer(payload);
    const { data } = yield createRetailer(retailer)
    const response = yield getRetailers({
      limit: 20,
      page: 1,
      brandId: "",
      cityId: "",
    })
    yield put(getRetailersSuccess(response))
  } catch (e) {
    // console.log(e.response)
    yield createRetailerFailed(e)
  }
}

function* deleteRetailerSaga({ payload: retailers }) {
  try {
    // const result = yield new Repository().deleteRetailer(payload);
    //const data = yield new Repository().getRetailers();
    yield put(deleteRetailerSuccess(retailers))
    yield put(getRetailersSuccess(payload))
  } catch (e) {
    console.log("error delete:", e)
    yield put(deleteRetailerFailed(e))
  }
}

function* updateRetailerSaga({ payload: retailer }) {
  try {
    const { id, ...updatedRetailer } = retailer
    // console.log("updatedRetailer",updatedRetailer)
    // console.log("retailer",retailer)
    const data = yield updateRetailer(id, updatedRetailer)
    // console.log("data",data)
    const response = yield getRetailers({
      limit: 20,
      page: 1,
      brandId: "",
      cityId: "",
    })
    yield put(getRetailersSuccess(response))
  } catch (e) {
    console.log("error update:", e.response)
    yield put(updateRetailerFailed(e))
  }
}

function* resetRetailersMessagesSaga() {
  yield put(resetRetailersMessagesSuccess())
}

export default function* retailersSaga() {
  yield all([
    takeEvery(actionTypes.GET_RETAILERS_REQUEST, getRetailersSaga),
    takeEvery(actionTypes.GET_RETAILERS_STATS_REQUEST, getRetailersStatsSaga),
    takeEvery(actionTypes.GET_RETAILERS_BY_ID_REQUEST, getRetailersByIdSaga),
    takeEvery(actionTypes.CREATE_RETAILER_REQUEST, createRetailerSaga),
    takeEvery(actionTypes.DELETE_RETAILER_REQUEST, deleteRetailerSaga),
    takeEvery(actionTypes.UPDATE_RETAILER_REQUEST, updateRetailerSaga),
    takeEvery(actionTypes.RESET_RETAILERS_MESSAGES, resetRetailersMessagesSaga),
  ])
}
