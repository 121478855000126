import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ADMINS,
  GET_ADMIN_PROFILE,
  ADD_NEW_ADMIN,
  DELETE_ADMIN,
  UPDATE_ADMIN,
  RESET_ADMINS_MESSAGES,
  UPDATE_PROFILE,
} from "./actionTypes"

import {
  getAdminsSuccess,
  getAdminsFail,
  getAdminProfileSuccess,
  getAdminProfileFail,
  addAdminFail,
  addAdminSuccess,
  updateAdminSuccess,
  updateAdminFail,
  deleteAdminSuccess,
  deleteAdminFail,
  resetAdminsMessagesSuccess,
  updateProfilSuccess,
  updateProfilFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserProfile,
  getAdmins,
  createAdmin,
  updateAdmin,
  getUser,
  getAdmin,
} from "../../helpers/api_helper"

function* fetchAdmins({ payload }) {
  try {
    console.log("payloadAdmin", payload)
    const data = yield getAdmins(payload)
    yield put(getAdminsSuccess(data))
  } catch (error) {
    yield put(getAdminsFail(error))
  }
}

function* fetchAdminProfile({ payload }) {
  try {
    const response = yield getAdmin(payload)
    yield put(getAdminProfileSuccess(response))
  } catch (error) {
    yield put(getAdminProfileFail(error))
  }
}

function* onUpdateAdmin({ payload: user }) {
  try {
    const { id, ...updatedAdmin } = user
    updatedAdmin.roles = updatedAdmin.roles?.map(el => {
      return el.id
    })
    const response = yield updateAdmin(id, updatedAdmin)

    const data = yield getAdmins({
      page: 1,
      limit: 100,
    })
    yield put(getAdminsSuccess(data))
  } catch (error) {
    yield put(updateAdminFail(error))
  }
}
function* onUpdateProfile({ payload: profile }) {
  try {
    const { id, ...updatedProfile } = profile

    yield put(updateProfilSuccess(updatedProfile))
  } catch (error) {
    yield put(updateProfilFail(error))
  }
}

function* onDeleteAdmin({ payload: id }) {
  try {
    //const response = yield call(deleteAdmin, user) replace with api
    yield put(deleteAdminSuccess(id))
  } catch (error) {
    yield put(deleteAdminFail(error))
  }
}

function* resetAdminsMessagesSaga() {
  yield put(resetAdminsMessagesSuccess())
}

function* onAddNewAdmin({ payload: user }) {
  try {
    //console.log("----", user)
    user.roles = user.roles?.map(role => {
      return role.id
    })
    const response = yield createAdmin(user)
    const data = yield getAdmins({
      page: 1,
      limit: 100,
    })
    yield put(getAdminsSuccess(data))
    console.log("data++++++++", data)
  } catch (error) {
    yield put(addAdminFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_ADMINS, fetchAdmins)
  yield takeEvery(GET_ADMIN_PROFILE, fetchAdminProfile)
  yield takeEvery(GET_ADMIN_PROFILE, fetchAdminProfile)
  yield takeEvery(ADD_NEW_ADMIN, onAddNewAdmin)
  yield takeEvery(UPDATE_ADMIN, onUpdateAdmin)
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(DELETE_ADMIN, onDeleteAdmin)
  yield takeEvery(RESET_ADMINS_MESSAGES, resetAdminsMessagesSaga)
}

export default usersSaga
