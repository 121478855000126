export const GET_OFFICES_SUCCESS = "GET_OFFICES_SUCCESS"
export const GET_OFFICES_REQUEST = "GET_OFFICES_REQUEST"
export const GET_OFFICES_FAILED = "GET_OFFICES_FAILED"
export const CREATE_OFFICE_REQUEST = "CREATE_OFFICE_REQUEST"
export const GET_OFFICE_BY_ID_REQUEST = "GET_OFFICE_BY_ID_REQUEST"
export const CREATE_OFFICE_SUCCESS = "CREATE_OFFICE_SUCCESS"
export const CREATE_OFFICE_FAILED = "CREATE_OFFICE_FAILED"
export const DELETE_OFFICE_SUCCESS = "DELETE_OFFICE_SUCCESS"
export const DELETE_OFFICE_FAILED = "DELETE_OFFICE_FAILED"
export const DELETE_OFFICE_REQUEST = "DELETE_OFFICE_REQUEST"
export const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS"
export const UPDATE_OFFICE_FAILED = "UPDATE_OFFICE_FAILED"
export const UPDATE_OFFICE_REQUEST = "UPDATE_OFFICE_REQUEST"
export const RESET_OFFICES_MESSAGES = "RESET_OFFICES_MESSAGES"
export const RESET_OFFICE_MESSAGES_SUCCESS = "RESET_OFFICE_MESSAGES_SUCCESS"
