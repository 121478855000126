import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  USER_LOADED,
  USER_REQUESTED,
} from "./actionTypes"
import {
  apiError,
  fulfillUser,
  loginSuccess,
  logoutUserSuccess,
  requestUser,
  logoutUser,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeLogin,
  postJwtLogin,
  postLogin,
  postSocialLogin,
} from "../../../helpers/api_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    yield put(requestUser({ accessToken: response.token, history: history }))
  } catch (error) {
    yield put(apiError(error.response?.status))
  }
}

function* userRequestedSaga({ payload: { accessToken, history, pathname } }) {
  try {
    const user = yield getLoggedInUser(accessToken)
    yield put(fulfillUser(user))
    if (pathname !== undefined || pathname !== "/login") {
      history.push(pathname)
    }
    console.log("user++++++++++", user)
    if (user.roles[0]?.name == "admin") {
      history.push("/dashboard")
    } else if (user.roles[0]?.name != "admin") {
      history.push("/cards-list")
    }
    // if (pathname == "/login" || pathname == undefined) {
    //   history.push("/dashboard")
    // }
  } catch (error) {
    history.push("/login")
    yield put(apiError(error))
  }
}

function* logoutUserSaga({ payload: { history } }) {
  history.push("/login")
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(USER_REQUESTED, userRequestedSaga)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
}

export default authSaga
