import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../components/Common/TableContainer"
//redux
import { useSelector, useDispatch } from "react-redux"

import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getBusinessPartner, getEwallet, getRetailers } from "store/actions"

const EwalletBalance = props => {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",  
    adminId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })
  const {
    ewalletData,
    error,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.ewallets)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getRetailers(filter))
    dispatch(getEwallet(filter))
  }, [dispatch, filter])

  const columns1 = useMemo(
    () => [
      {
        Header: props.t("Member Name"),
        accessor: "name",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.user?.firstname + " " + cellProps.user?.lastname ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Type"),
        accessor: "type",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.user?.accountType || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("E-wallet Balance"),
        accessor: "wallet",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.balance || props.t("---")}</>
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <TabPane tabId="2" id="release">
        <div>
          <TableContainer
            columns={columns1}
            data={ewalletData}
            isGlobalFilter={true}
            isAddOptions={false}
            ActionReleaseReject={false}
            customPageSize={100}
            setPageNumber={setPageNumber}
            setLimit={handleLimit}
            nextPage={nextPage}
            previousPage={previousPage}
            currentPage={currentPage}
            total={total}
            limit={limit}
            loading={loading}
            success={success}
          />
        </div>
      </TabPane>
    </React.Fragment>
  )
}

EwalletBalance.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(EwalletBalance)
