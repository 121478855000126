import { getService, postService, putService } from "helpers/api_helper"
import { all, put, takeEvery } from "redux-saga/effects"

import {
  createserviceFail,
  createserviceSuccess,
  deleteServiceFail,
  deleteServiceSuccess,
  getServicesFaild,
  getServicesSuccess,
  resetServicesMessages,
  updateserviceFail,
  resetServiceMessagesSuccess,
  updateserviceSuccess,
} from "./actions"
import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  GET_SERVICES,
  RESET_MESSAGES,
  UPDATE_SERVICE,
  RESET_SERVICE_MESSAGES,
} from "./actionTypes"

function* getServicesSaga({ payload }) {
  try {
    const response = yield getService(payload || { page: 1, limit: 1000 })
    yield put(getServicesSuccess(response))
  } catch (e) {
    yield put(getServicesFaild(e))
  }
}

function* deleteServicesSaga({ payload: id }) {
  try {
    //const response = yield call(deleteUser, user) replace with api
    yield put(deleteServiceSuccess(id))
  } catch (error) {
    yield put(deleteServiceFail(error))
  }
}

function* createServicesSaga({ payload: service }) {
  try {
    const data = yield postService(service)
    // console.log("create service",response)
    const response = yield getService({ limit: 20, page: 1 })
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(createserviceFail(error))
  }
}

function* updateServicesSaga({ payload: service }) {
  // console.log("saga", service)
  try {
    const { id, ...updatedService } = service
    const data = yield putService(id, updatedService)
    const response = yield getService({ limit: 20, page: 1 })
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(updateserviceFail(error))
  }
}

function* resetServicesMessagesSaga() {
  yield put(resetServicesMessages())
}

function* resetServiceMessagesSaga() {
  yield put(resetServiceMessagesSuccess())
}

export default function* mdmsSaga() {
  yield all([
    takeEvery(GET_SERVICES, getServicesSaga),
    takeEvery(DELETE_SERVICE, deleteServicesSaga),
    takeEvery(UPDATE_SERVICE, updateServicesSaga),
    takeEvery(CREATE_SERVICE, createServicesSaga),
    takeEvery(RESET_MESSAGES, resetServicesMessagesSaga),
    takeEvery(RESET_SERVICE_MESSAGES, resetServiceMessagesSaga),
  ])
}
