import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logo from "../../assets/images/logo.svg";
import CarouselPage from "pages/AuthenticationInner/CarouselPage";
import { Logo } from "pages/Retailers/StatsRetailer/StatsCol";

const ResetPasswordEmail = () => {

  //meta title
  document.title = "Mazaya";

  const [apiMessage, setApiMessage] = useState({
    success: false,
    error: false,
    message: ""
  })
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });


  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <a href="/" className="d-block auth-logo">
                        <img
                          src={logo}
                          alt=""
                          height="80"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logo}
                          alt=""
                          height="80"
                          className="auth-logo-light"
                        />
                      </a>
                    </div>
                    <div className="my-auto">
                      {!apiMessage.success &&
                        <><div>
                          <h5 className="text-primary"> Reset Password</h5>
                        </div>

                          <div className="mt-4">
                            {apiMessage.error &&
                              <div
                                className="alert alert-danger text-center mb-4"
                                role="alert"
                              >
                                An error has occured ! Please try again.
                              </div>}

                            <Form className="form-horizontal"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                  }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-md "
                                  type="submit"
                                >Reset</button>
                              </div>
                            </Form>

                            <div className="mt-5 text-center">
                              <p>
                                Remember It ?{" "}
                                <Link
                                  to="login"
                                  className="fw-medium text-primary"
                                >
                                  {" "}
                                  Sign In here
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </>
                      }
                      {
                        apiMessage.success &&
                        <div className="text-center">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title rounded-circle bg-light">
                              <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                            </div>
                          </div>
                          <div className="p-2 mt-4">
                            <h4>Success !</h4>
                            <p className="text-muted">
                              At vero eos et accusamus et iusto odio dignissimos
                              ducimus qui blanditiis praesentium voluptatum
                              deleniti atque corrupti quos dolores et
                            </p>
                            <div className="mt-4">
                              <Link to="login" className="btn btn-success">
                                Back to Home
                              </Link>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Mazaya
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordEmail;
