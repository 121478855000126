import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import ReactSwitch from "react-switch"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getMDMPayment,
  makeDefaultPayment,
  updateMDMPayment,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Label,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import PaymentModal from "./PaymentModal"
import { checkPermission } from "helpers/functions"
import ConfirmModal from "components/Common/ConfirmModal"

function PaymentMethod(props) {
  //meta title
  document.title = "Mazaya"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [payment, setPayment] = useState(null)
  const [show, setShow] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    status: "active",
  })

  const dispatch = useDispatch()

  const {
    paymentData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payments)
  const userRoles = useSelector(state => state.Login.user?.roles)

  useEffect(() => {
    dispatch(getMDMPayment(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (!isEmpty(paymentData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [paymentData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPayment(null)
    } else {
      setModal(true)
    }
  }

  const toggleShow = () => {
    setShow(!show)
  }

  const handleOrderClick = arg => {
    const payment = arg
    setPayment({
      ...payment,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = payment => {
    setPayment(payment)
    setDeleteModal(true)
  }

  const onClickMakeDefault = payment => {
    setPayment(payment)
    setConfirmModal(true)
  }

  const handleDeleteLevel = () => {
    if (payment.id) {
      dispatch(updateMDMPayment({ id: payment.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMPayment(filter))
    }
  }

  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const changeDefault = () => {
    if (payment.id) {
      dispatch(makeDefaultPayment({ id: payment.id }))
      setConfirmModal(false)
      dispatch(getMDMPayment(filter))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("English Name"),
        accessor: "nameEn",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() => {
                setPayment(cellProps)
                toggleShow()
              }}
            >
              {cellProps.nameEn}
            </div>
          )
        },
      },
      {
        Header: props.t("Default Methode"),
        accessor: "is_default",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="">
              <input
                type="checkbox"
                className="form-check-input mx-1"
                name="status"
                onClick={() => {
                  const orderData = cellProps
                  console.log("++", cellProps.is_default)
                  onClickMakeDefault(orderData)
                }}
                checked={cellProps.is_default == true ? true : false}
              />
            </div>
          )
        },
      },

      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return <>{props.t(cellProps.status)}</>
        },
      },
      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {checkPermission(userRoles, [
                "MasterData",
                "PaymentMethod",
                "Update",
              ]) && (
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    handleOrderClick(orderData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </Link>
              )}
              {checkPermission(userRoles, [
                "MasterData",
                "PaymentMethod",
                "Delete",
              ]) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    onClickDelete(orderData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <PaymentModal
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        payment={payment}
      />
      <ConfirmModal
        show={confirmModal}
        onUpdateClick={changeDefault}
        onCloseClick={() => setConfirmModal(false)}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLevel}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Payment Method")}
            breadcrumbItem={props.t("Payment Method List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={paymentData}
                    //isGlobalFilter={true}
                    isAddOptions={checkPermission(userRoles, [
                      "MasterData",
                      "PaymentMethod",
                      "Create",
                    ])}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    setLimit={handleLimit}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PaymentMethod.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(PaymentMethod)
