import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { useSelector, useDispatch } from "react-redux"
import {
  createMDMParrainage,
  getMDMParrainage,
  updateMDMParrainage,
} from "store/actions"
import { withTranslation } from "react-i18next"

const PaymentModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, parrainage, isEdit } = props
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
  })

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (parrainage && parrainage.id) || "",
      nameEn: (parrainage && parrainage.nameEn) || "",
      value: (parrainage && parrainage.value) || "",
      status: (parrainage && parrainage.status) || "active",
      type: (parrainage && parrainage.type) || "parrainageType",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter name")),
      value: Yup.string().required(props.t("Please Enter number of accounts")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedPayment = {
          id: parrainage.id,
          nameEn: values.nameEn,
          value: values.value,
          status: values.status ? "active" : "inactive",
        }
        // update order
        dispatch(updateMDMParrainage(updatedPayment))
        // console.log("updated payment methode", updatedPayment)
        validation.resetForm()
      } else {
        const newPayment = {
          nameEn: values["nameEn"],
          status: values["status"],
          value: parseInt(values["value"]),
          type: values["type"],
        }
        // console.log("newPaymentMethod", newPayment)

        // save new method-payment
        dispatch(createMDMParrainage(newPayment))
        dispatch(getMDMParrainage(filter))

        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit
          ? props.t("Edit Parrainage Type")
          : props.t("Add Parrainage Type")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Number of accounts")}
                </Label>
                <Input
                  name="value"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.value || ""}
                  invalid={
                    validation.touched.value && validation.errors.value
                      ? true
                      : false
                  }
                />
                {validation.touched.value && validation.errors.value ? (
                  <FormFeedback type="invalid">
                    {validation.errors.value}
                  </FormFeedback>
                ) : null}
              </div>

              {/* <div className="mb-3">
                <Label className="form-label">{props.t("Status")}</Label>
                <div className="form-check form-switch mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    //style={{borderColor: '#34c38f'}}
                    name="status"
                    onClick={e => {
                      validation.setFieldValue(
                        "status",
                        !validation.values.status
                      )
                    }}
                    onBlur={validation.handleBlur}
                    checked={validation.values?.status || false}
                    onInvalid={
                      validation.touched.status && validation.errors.status
                        ? true
                        : false
                    }
                  />
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type="invalid">
                      {validation.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PaymentModal)
