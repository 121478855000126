import {
    CARDTYPE_MESSAGES_RESET,
    RESET_CARDTYPE_MESSAGES_SUCCESS,
    CARDTYPE_MESSAGES_SUCCESS,
    CREATE_CARDTYPE_FAILED,
    CREATE_CARDTYPE_REQUEST,
    CREATE_CARDTYPE_SUCCESS,
    DELETE_CARDTYPE_FAILED,
    DELETE_CARDTYPE_REQUEST,
    DELETE_CARDTYPE_SUCCESS,
    GET_CARDTYPE_BY_NAME_FAILED,
    GET_CARDTYPE_BY_NAME_REQUEST,
    GET_CARDTYPE_BY_NAME_SUCCESS,
    GET_CARDTYPE_FAILED,
    GET_CARDTYPE_REQUEST,
    GET_CARDTYPE_SUCCESS,
    UPDATE_CARDTYPE_FAILED,
    UPDATE_CARDTYPE_REQUEST,
    UPDATE_CARDTYPE_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    cardTypeData: [],
    success: "",
    error: "",
    nextPage: null,
    previousPage: null,
    limit: 0,
    total:0,
    currentPage: 1
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_CARDTYPE_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_CARDTYPE_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          cardTypeData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_CARDTYPE_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_CARDTYPE_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_CARDTYPE_BY_NAME_SUCCESS:
        return {
          ...state,
          cardTypeData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_CARDTYPE_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_CARDTYPE_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_CARDTYPE_SUCCESS:
        return {
          ...state,
          cardTypeData: [...state.cardTypeData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_CARDTYPE_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_CARDTYPE_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_CARDTYPE_SUCCESS:
        return {
          ...state,
          cardTypeData: state.cardTypeData.filter(
            cardType => cardType.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_CARDTYPE_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_CARDTYPE_REQUEST:
        return {
          ...state,
          loading: true,
          success: "UPDATE",
          error: "",
        }
      case UPDATE_CARDTYPE_SUCCESS:
        return {
          ...state,
          cardTypeData: state.cardTypeData.map(cardType =>
            cardType.id.toString() === action.payload.id.toString()
              ? { cardType, ...action.payload }
              : cardType
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_CARDTYPE_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case CARDTYPE_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_CARDTYPE_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }
      default:
        return state
    }
  }
  
  export default reducer
  