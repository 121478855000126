import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "react-datepicker/dist/react-datepicker.css"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { map } from "lodash"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import PayoutSummary from "./PayoutSummary"
import PayoutRelease from "./PayoutRelease"
import PaymentProcess from "./PaymentProcess"
import { getPayoutStats } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { checkPermission } from "helpers/functions"
const Payout = props => {
  //meta title
  document.title = "Mazaya"
  const {payoutStats} = useSelector(state => state.payout)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPayoutStats())
  }, [dispatch])
  const {
    payoutData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payout)
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Pending"),
      iconClass: "bx bxs-chevron-up-square",
      text: 0,
      color: "warning",
    },
    {
      title: props.t("Approved"),
      iconClass: "bx bx-comment-check",
      text: 0,
      color: "info",
    },
    {
      title: props.t("Paid"),
      iconClass: "bx bx-dollar",
      text: 0,
      color: "success",
    },
    {
      title: props.t("Rejected"),
      iconClass: "bx bx-window-close",
      text: 0,
      color: "danger",
    },
  ])
  useEffect(() => {
    if(payoutData != undefined){
      setMiniCards([
        {
          title: props.t("Pending"),
          iconClass: "bx bxs-chevron-up-square",
          text: payoutStats?.totalPending,
          color: "warning",
        },
        {
          title: props.t("Approved"),
          iconClass: "bx bx-comment-check",
          text: payoutStats?.totalReleased,
          color: "info",
        },
        {
          title: props.t("Paid"),
          iconClass: "bx bx-dollar",
          text: payoutStats?.totalPaid,
          color: "success",
        },
        {
          title: props.t("Rejected"),
          iconClass: "bx bx-window-close",
          text: payoutStats?.totalRejected,
          color: "danger",
        },
      ]
      )
    }
  }, [payoutStats])
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Accounting")}
            breadcrumbItem={props.t("Payout")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    color={card.color}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    {checkPermission(userRoles, ["Accounting", "Payout", "List"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("Payout Summary")}
                      </NavLink>
                    </NavItem>}
                    {checkPermission(userRoles, ["Accounting", "Payout", "ListPayoutRelease"]) && 
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {props.t("Payout Release")}
                      </NavLink>
                    </NavItem>}
                    {checkPermission(userRoles, ["Accounting", "Payout", "ListProcessPayment"]) && 
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        {props.t("Process Payment")}
                      </NavLink>
                    </NavItem>}
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                   {checkPermission(userRoles, ["Accounting", "Payout", "List"]) && <PayoutSummary/>}
                   {checkPermission(userRoles, ["Accounting", "Payout", "ListPayoutRelease"]) && <PayoutRelease/>}
                    {checkPermission(userRoles, ["Accounting", "Payout", "ListProcessPayment"]) &&<PaymentProcess/>}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Payout.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Payout)
