import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap"

// TableContainer

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Images
import profile1 from "assets/images/users/avatar-6.jpg"
import ReactToPrint from "react-to-print"
// import charts
import { getAdminProfile, getCardId } from "store/actions"
import moment from "moment"
import { withTranslation } from "react-i18next"
import freeCardImage from "../../assets/images/freeCard.png"
const showCard = props => {
  //meta title
  document.title = "Mazaya"
  const params = useParams()
  const id = params.id

  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const printRef = useRef()
  const { card, success, error } = useSelector(state => state.cards)

  useEffect(() => {
    if (id) {
      dispatch(getCardId(id))
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Card")}
            breadcrumbItem={props.t("Card")}
          />
          <ReactToPrint
            trigger={() => (
              <Button color="primary">{props.t("Print Card")}</Button>
            )}
            content={() => printRef.current}
          />

          <Row className="justify-content-center">
            <Col xl="6">
              <Card className="overflow-hidden">
                <div ref={printRef} className="bg-soft">
                  <Row>
                    <Col xs="12" className="justify-content-center">
                      <div className="text-primary p-3">
                        <h1 className="text-primary text-center">
                          {card?.cardType?.name}
                        </h1>
                      </div>
                    </Col>
                    <Col xs="12" className="d-flex justify-content-center">
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={
                            card?.cardType?.imageUrl == ""
                              ? freeCardImage
                              : card?.cardType?.imageUrl
                          }
                          alt=""
                          className="img-fluid"
                          style={{ marginBottom: "20px" }}
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            left: "20%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "35px",
                            textAlign: "left",
                          }}
                        >
                          <span>
                            {card?.customer?.firstname +
                              " " +
                              card?.customer?.lastname}
                          </span>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "37%",
                            right: "10%",
                            textAlign: "right",
                            color: "black",
                            fontSize: "20px",
                          }}
                        >
                          <img
                            src={card?.qrCode}
                            alt=""
                            style={{ width: "52%" }} // Adjust size as needed
                            className="img-fluid"
                          />
                        </div>

                        <div
                          style={{
                            position: "absolute",
                            bottom: "20%", // Position from the bottom of the card
                            left: "2%",
                            right: "0",
                            display: "flex",
                            flexDirection: "column", // Change to column layout
                            padding: "0 20px",
                            color: "black",
                            fontSize: "24px",
                          }}
                        >
                          <span style={{ margin: "0" }}>
                            {"Exp : " + card?.validUntil}
                          </span>
                        </div>
                      </div>
                    </Col>

                    {/* <Col
                      xs="12"
                      className="d-flex justify-content-center mt-10"
                    >
                      <img src={card?.qrCode} alt="" className="img-fluid " />
                    </Col> */}
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <Row>
                    <Col sm={12}>
                      <div className="mb-4"></div>
                      <div className="pt-4">
                        <Row>
                          <Col xs="3">
                            <p className="text-muted mb-0">
                              {props.t("Card N°")}
                            </p>
                            <h5 className="font-size-15">{card?.code}</h5>
                          </Col>
                          <Col xs="3">
                            <p className="text-muted mb-0">
                              {props.t("achat date")}
                            </p>
                            <h5 className="font-size-15">
                              {moment(card?.createdAt).format("YYYY-MM-DD")}
                            </h5>
                          </Col>
                          <Col xs="3">
                            <p className="text-muted mb-0">
                              {props.t("Valid until")}
                            </p>
                            <h5 className="font-size-15">{card?.validUntil}</h5>
                          </Col>
                          <Col xs="3">
                            <p className="text-muted mb-0">
                              {props.t("Parrinage Number")}
                            </p>
                            <h5 className="font-size-15">
                              {card?.cardType?.nbParrainage}
                            </h5>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Holders List")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{props.t("Customer Name")}</th>
                          <th>{props.t("Using Number")}</th>
                          <th>{props.t("Last using Date")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {card?.holders?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.firstname + " " + item.lastname}</td>
                            <td>{item.scans?.length}</td>
                            <td>
                              {moment(item?.scans[length]?.date).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

showCard.propTypes = {
  card: PropTypes.any,
  onGetcard: PropTypes.func,
}

export default withRouter(withTranslation()(showCard))
