import {
  CARDTYPE_MESSAGES_RESET,
  CREATE_CARDTYPE_FAILED,
  CREATE_CARDTYPE_REQUEST,
  CREATE_CARDTYPE_SUCCESS,
  DELETE_CARDTYPE_FAILED,
  DELETE_CARDTYPE_REQUEST,
  DELETE_CARDTYPE_SUCCESS,
  GET_CARDTYPE_BY_NAME_FAILED,
  GET_CARDTYPE_BY_NAME_REQUEST,
  GET_CARDTYPE_BY_NAME_SUCCESS,
  GET_CARDTYPE_FAILED,
  GET_CARDTYPE_REQUEST,
  GET_CARDTYPE_SUCCESS,
  UPDATE_CARDTYPE_FAILED,
  UPDATE_CARDTYPE_REQUEST,
  RESET_CARDTYPE_MESSAGES_SUCCESS,
  RESET_CARDTYPE_MESSAGES,
  UPDATE_CARDTYPE_SUCCESS,
} from "./actionTypes.js"

export const getCardTypeByName = name => ({
  type: GET_CARDTYPE_BY_NAME_REQUEST,
  payload: name,
})

export const getCardTypeByNameSuccess = payload => ({
  type: GET_CARDTYPE_BY_NAME_SUCCESS,
  payload,
})

export const getCardTypeByNameFailed = payload => ({
  type: GET_CARDTYPE_BY_NAME_FAILED,
  payload,
})

export const getCardType = filter => ({
  type: GET_CARDTYPE_REQUEST,
  payload: filter,
})

export const getCardTypeSuccess = brand => ({
  type: GET_CARDTYPE_SUCCESS,
  payload: brand,
})

export const getCardTypeFailed = payload => ({
  type: GET_CARDTYPE_FAILED,
  payload,
})

export const createCardType = brand => ({
  type: CREATE_CARDTYPE_REQUEST,
  payload: brand,
})

export const createCardTypeSuccess = brand => ({
  type: CREATE_CARDTYPE_SUCCESS,
  payload: brand,
})

export const createCardTypeFailed = payload => ({
  type: CREATE_CARDTYPE_FAILED,
  payload,
})

export const deleteCardType = response => ({
  type: DELETE_CARDTYPE_REQUEST,
  payload: response,
})

export const deleteCardTypeSuccess = response => ({
  type: DELETE_CARDTYPE_SUCCESS,
  payload: response,
})

export const deleteCardTypeFailed = response => ({
  type: DELETE_CARDTYPE_FAILED,
  payload: response,
})

export const updateCardType = brand => ({
  type: UPDATE_CARDTYPE_REQUEST,
  payload: brand,
})

export const updateCardTypeSuccess = brand => ({
  type: UPDATE_CARDTYPE_SUCCESS,
  payload: brand,
})

export const updateCardTypeFailed = payload => ({
  type: UPDATE_CARDTYPE_FAILED,
  payload,
})

export const resetCardTypeMessages = () => ({
  type: CARDTYPE_MESSAGES_RESET,
})

export const resetCardTypeMessagesSuccess = () => ({
  type: RESET_CARDTYPE_MESSAGES_SUCCESS,
})
