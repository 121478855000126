import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"

//redux
import { useDispatch, useSelector } from "react-redux"
import * as moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { map } from "lodash"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import PayInModal from "./PayInModal"
import { getPayinStats, getPayin } from "store/actions"
import { Link } from "react-router-dom"
import PayInPrint from "./PayInPrint"
import { checkPermission } from "helpers/functions"

const PayIn = props => {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",
    from: "",
    to: "",
    status: "",
    page: 1,
    limit: 100,
  })
  const {
    payinData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payin)
  const { 
    payinStats   
  } = useSelector(state => state.payin)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()
  const [payInModal, setPayInModal] = useState(false)
  const [payInPrintModal, setPayInPrintModal] = useState(false)
  const [payIn, setPayIn] = useState({})
  const [payiin, setPayiin] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  useEffect(() => {
    dispatch(getPayin(filter))
    dispatch(getPayinStats())
  }, [dispatch, filter])
  
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Pending"),
      iconClass: "bx bxs-chevron-up-square",
      text: 0,
      color: "warning",
    },
    {
      title: props.t("Paid"),
      iconClass: "bx bx-dollar",
      text: 0,
      color: "success",
    },
  ])


  useEffect(() => {
    if(payinData != undefined){
      setMiniCards([
        {
          title: props.t("Pending"),
          iconClass: "bx bxs-chevron-up-square",
          text: payinStats?.totalPending,
          color: "warning",
        },
        {
          title: props.t("Paid"),
          iconClass: "bx bx-dollar",
          text: payinStats?.totalPaid,
          color: "success",
        },
      ]
      )
    }
  }, [payinStats])
  const columns = useMemo(
    () => [
      {
        Header: props.t("PayIn id"),
        accessor: "",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.id}</>
        },
      },

      {
        Header: props.t("Retailer Name"),
        accessor: "createdAt",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.retailer &&
                cellProps.retailer?.firstname +
                  " " +
                  cellProps.retailer?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Last Balance"),
        accessor: "balance",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.retailer?.wallet?.balance || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Amount"),
        accessor: "createdBy",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.amount || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Payment Method"),
        accessor: "amount",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {" "}
              {cellProps.payinMethod
                ? (currentLanguage == "ar" && cellProps.payinMethod?.nameAr) ||
                  (currentLanguage == "en" && cellProps.payinMethod?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.payinMethod?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Paiement date"),
        accessor: "payoutmethod",

        accessor: cellProps => {
          return (
            <>
              {" "}
              {(cellProps.createdAt &&
                moment(new Date(cellProps.createdAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Payed by"),
        accessor: "releasedAt ",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdBy &&
                cellProps.createdBy?.firstname +
                  " " +
                  cellProps.createdBy?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
              {checkPermission(userRoles, ["Retailer", "Payin", "Update"]) && 
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>}
                {checkPermission(userRoles, ["Retailer", "Payin", "Print"]) &&
                <Link
                  to="#"
                  onClick={() => {
                    let payinPaiement = cellProps.row.original
                    handlePayinPrintClick(payinPaiement)
                  }}
                  className="text-success "
                >
                  <i className="fa fa-print font-size-15" />
                </Link>}
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const handlePayinPrintClick = payin => {
    setPayIn(payin)
    setIsEdit(true)
    setPayInPrintModal(true)
  }
  const handleOrderClick = payin => {
    setPayiin(payin)
    setIsEdit(true)
    setPayInModal(true)
  }
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handelAddPayIn = () => {
    setPayInModal(true)
    setIsEdit(false)
    // setPayiin(null)
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    }else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Accounting")}
            breadcrumbItem={props.t("PayIn")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    color={card.color}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("PayIn")}
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="summary">
                      <TableContainer
                        columns={columns}
                        data={payinData}
                        filterDate={true}
                        setDateFilter={handleDateFromTo}
                        isAddPayIn={checkPermission(userRoles, ["Retailer", "Payin", "Create"])}
                        handleOrderClicks={handelAddPayIn}
                        customPageSize={100}
                        setPageNumber={setPageNumber}
                        setLimit={handleLimit}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        total={total}
                        limit={limit}
                        loading={loading}
                        success={success}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <PayInModal
                modal={payInModal}
                payin={payiin}
                isEdit={isEdit}
                toggle={setPayInModal}
              />
              <PayInPrint
                modal={payInPrintModal}
                payin={payIn}
                isEdit={isEdit}
                toggle={setPayInPrintModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PayIn.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(PayIn)
