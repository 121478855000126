import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import logo from "../../assets/images/logo.svg"
import { Col } from "reactstrap"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg">
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <div style={{ marginTop: "20%" }} className="text-center ">
              <img src={logo} alt="" height="300" className="logo-image" />
            </div>
            <div className="mt-auto text-center p-4">
              <h4 className="mb-3">
                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                Welcome to<span className="text-primary"> Mazaya</span>
              </h4>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
