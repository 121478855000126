import React from "react"
import { Link } from "react-router-dom"

const CustId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const UserName = cell => {
  return cell.value ? cell.value : ""
}

// const  ArabicName = cell => {
//   return cell.value ? cell.value : ""
// }

const EnName = cell => {
  return cell.value ? cell.value : ""
}

export {
  CustId,
  UserName,
  // ArabicName,
  EnName,
}
