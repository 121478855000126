import {
  PAYMENT_MESSAGES_RESET,
  RESET_PAYMENT_MESSAGES_SUCCESS,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILED,
  DELETE_PAYMENT_REQUEST,
  DELETE_PAYMENT_SUCCESS,
  GET_PAYMENT_BY_NAME_FAILED,
  GET_PAYMENT_BY_NAME_REQUEST,
  GET_PAYMENT_BY_NAME_SUCCESS,
  GET_PAYMENT_FAILED,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILED,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  MAKE_DEFAULT_PAYMENT_REQUEST,
  MAKE_DEFAULT_PAYMENT_SUCCESS,
  MAKE_DEFAULT_PAYMENT_FAILED,
} from "./actionTypes"

export const initialState = {
  loading: false,
  paymentData: [],
  success: "",
  error: "",
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_PAYMENT_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        paymentData: [...data],
        ...filters,
        loading: false,
        success: "GET",
      }
    case GET_PAYMENT_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case GET_PAYMENT_BY_NAME_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_PAYMENT_BY_NAME_SUCCESS:
      return {
        ...state,
        paymentData: action.payload,
        loading: false,
        success: "SUCCESS",
      }
    case GET_PAYMENT_BY_NAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentData: [...state.paymentData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case CREATE_PAYMENT_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }
    case DELETE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentData: state.paymentData.filter(
          payment => payment.id.toString() !== action.payload.toString()
        ),
        success: "DELETE",
        loading: false,
      }
    case DELETE_PAYMENT_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case UPDATE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        countriesData: state.countriesData.map(payment =>
          payment.id.toString() === action.payload.id.toString()
            ? { payment, ...action.payload }
            : payment
        ),
        success: "UPDATE",
        loading: false,
      }
    case UPDATE_PAYMENT_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    case PAYMENT_MESSAGES_RESET:
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      }
    case RESET_PAYMENT_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      }
    case MAKE_DEFAULT_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case MAKE_DEFAULT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentData: state.paymentData.map(el => {
          if (el.id === action.payload) el = { ...el, is_default: true }
          else el = { ...el, is_default: false }
          return el
        }),
        success: "UPDATE",
        loading: false,
      }
    case MAKE_DEFAULT_PAYMENT_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
