import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap"

// TableContainer

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Images
import profile1 from "assets/images/users/avatar-6.jpg"
import bg from "assets/images/profile-img.png"
// import charts
import { getAdminProfile } from "store/actions"
import moment from "moment"
import { withTranslation } from "react-i18next"
const UserProfile = props => {
  //meta title
  document.title = "Mazaya"
  const params = useParams()
  const id = params.id

  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true)

  const { userProfile, success, error } = useSelector(state => state.admins)
  // const Offsymbol = () => {
  //   return (
  //     <div>
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           height: "100%",
  //           fontSize: 12,
  //           color: "#fff",
  //           paddingRight: 2,
  //         }}
  //       >
  //         {" "}
  //       </div>
  //       <div>
  //         <button type="button" className="btn btn-danger  btn-label">
  //           <i className="bx bx-block label-icon "></i> Danger
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }
  // const OnSymbol = () => {
  //   return (
  //     <div className="col-lg-4">
  //       <div
  //         className="col-lg-2"
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           height: "100%",
  //           fontSize: 12,
  //           color: "#fff",
  //           paddingRight: 2,
  //         }}
  //       >
  //         {" "}
  //       </div>
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           height: "100%",
  //         }}
  //       >
  //         <button
  //           type="button"
  //           className="btn btn-success  btn-label"
  //           onClick={handleClick}
  //           loading={loading}
  //         >
  //           <i className="bx bx-check-double label-icon"></i>{" "}
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }

  function handleClick() {
    setLoading(true)
  }

  useEffect(() => {
    if (id) {
      dispatch(getAdminProfile(id))
    }
  }, [dispatch])

  console.log("userProfile", userProfile)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("Profile")}
          />
          {/* <Row className="justify-content-start">
            <Col xl="5">
              <div className="mb-3">
                <Input
                  type="text"
                  placeholder={props.t("Search by username")}
                  //onChange={validation.handleChange}
                />
              </div>
            </Col>
            <Col xl="1">
              <Button
                className="btn btn-primary save-user"
                //onClick={validation.submitForm}
              >
                {props.t("Search")}
              </Button>
            </Col>
          </Row> */}
          <Row className="justify-content-start">
            <Col xl="6">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        {/* <h5 className="text-primary">Welcome!</h5> */}
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={bg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="8">
                      <div className="avatar-lg profile-user-wid mb-4">
                        <img
                          src={userProfile?.image_url || profile1}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>

                      <br />
                      <h5 className="font-size-15 text-truncate">
                        {userProfile?.firstname + " " + userProfile?.lastname}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {userProfile?.roles?.map(role => role.name)?.join("/")}
                      </p>
                    </Col>

                    {/* <Col sm={8}>
                      <div className="mb-4"></div>
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <p className="text-muted mb-0">Status</p>
                            <h5 className="font-size-15">
                              {userProfile.status}
                            </h5>
                          </Col>
                        </Row>
                      </div>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Personal Information")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{props.t("Full Name")}</th>
                          <td>
                            {userProfile?.firstname +
                              " " +
                              userProfile?.lastname}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Roles")} :</th>
                          <td>
                            {userProfile?.roles
                              ?.map(role => role.name)
                              ?.join(" ")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Mobile")} :</th>
                          <td>{userProfile?.phone || "Not provided"}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("E-mail")}:</th>
                          <td>{userProfile?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Joined")} :</th>
                          <td>
                            {moment(new Date(userProfile?.createdAt)).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(withTranslation()(UserProfile))
