
/**filter */
export const GET_CARDTYPE_BY_NAME_SUCCESS = "GET_CARDTYPE_BY_NAME_SUCCESS";
export const GET_CARDTYPE_BY_NAME_REQUEST = "GET_CARDTYPE_BY_NAME_REQUEST";
export const GET_CARDTYPE_BY_NAME_FAILED = "GET_CARDTYPE_BY_NAME_FAILED";

/**Get */
export const GET_CARDTYPE_SUCCESS = 'GET_CARDTYPE_SUCCESS';
export const GET_CARDTYPE_REQUEST = 'GET_CARDTYPE_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_CARDTYPE_FAILED = 'GET_CARDTYPE_FAILED';

/**Create */
export const CREATE_CARDTYPE_SUCCESS = 'CREATE_CARDTYPE_SUCCESS';
export const CREATE_CARDTYPE_FAILED = 'CREATE_CARDTYPE_FAILED';
export const CREATE_CARDTYPE_REQUEST = 'CREATE_CARDTYPE_REQUEST';
/**Delete */
export const DELETE_CARDTYPE_SUCCESS = 'DELETE_CARDTYPE_SUCCESS';
export const DELETE_CARDTYPE_FAILED = 'DELETE_CARDTYPE_FAILED';
export const DELETE_CARDTYPE_REQUEST = 'DELETE_CARDTYPE_REQUEST';
/**Update */
export const UPDATE_CARDTYPE_SUCCESS = 'UPDATE_CARDTYPE_SUCCESS';
export const UPDATE_CARDTYPE_FAILED = 'UPDATE_CARDTYPE_FAILED';
export const UPDATE_CARDTYPE_REQUEST = 'UPDATE_CARDTYPE_REQUEST';

export const CARDTYPE_MESSAGES_RESET = 'CARDTYPE_MESSAGES_RESET';
export const CARDTYPE_MESSAGES_SUCCESS = 'CARDTYPE_MESSAGES_SUCCESS'
export const RESET_CARDTYPE_MESSAGES_SUCCESS = 'RESET_CARDTYPE_MESSAGES_SUCCESS';
export const RESET_CARDTYPE_MESSAGES = 'RESET_CARDTYPE_MESSAGES';

