import React, { useEffect } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  createMDMCategory,
  getMDMCategories,
  updateMDMCategory,
} from "store/actions"
import { withTranslation } from "react-i18next"

const SubCategoryModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, category, isEdit } = props
  const { categoriesData } = useSelector(state => state.categories)
  useEffect(() => {
    {
      dispatch(getMDMCategories({ page: 1, limit: 100 }))
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameEn: (category && category.nameEn) || "",
      parentId: (category && category.parentId) || null,
      type: (category && category.type) || "category",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter Category name")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCategory = {
          id: category ? category.id : 0,
          nameEn: values.nameEn,
          parentId: values.parentId,
          status: values.status,
          type: "category",
        }
        // update
        // console.log("updateCategory", updateCategory)
        dispatch(updateMDMCategory(updateCategory))
        validation.resetForm()
      } else {
        const newCategory = {
          nameEn: values["nameEn"],
          parentId: parseInt(values["parentId"]),
          type: values["type"],
          status: "active",
        }
        // save new city
        dispatch(createMDMCategory(newCategory))
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Category") : props.t("Add Category")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Parent Category")}
                </Label>
                <Input
                  type="select"
                  name="parentId"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.parentId || []}
                  invalid={
                    validation.touched.parentId && validation.errors.parentId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {categoriesData
                    .filter(item => item.parentId == null)
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nameEn}
                      </option>
                    ))}
                </Input>
                {validation.touched.parentId && validation.errors.parentId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.parentId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(SubCategoryModal)
