import {
    CREATE_SERVICE,
    CREATE_SERVICE_FAIL,
    CREATE_SERVICE_SUCCESS,
    DELETE_SERVICE,
    DELETE_SERVICE_FAIL,
    DELETE_SERVICE_SUCCESS,
    GET_SERVICES,
    GET_SERVICES_FAIL,
    GET_SERVICES_SUCCESS,
    RESET_MESSAGES,
    UPDATE_SERVICE,
    RESET_SERVICE_MESSAGES_SUCCESS,
    RESET_SERVICE_MESSAGES,
    UPDATE_SERVICE_FAIL,
    UPDATE_SERVICE_SUCCESS
} from "./actionTypes"

export const getServices = (filter) => ({
    type: GET_SERVICES,
    payload: filter,
})


export const getServicesSuccess = services => ({
    type: GET_SERVICES_SUCCESS,
    payload: services,
})

export const getServicesFaild = services => ({
    type: GET_SERVICES_FAIL,
    payload: services,
})

export const createService = Service => ({
    type: CREATE_SERVICE,
    payload: Service,
})

export const createserviceSuccess = Service => ({
    type: CREATE_SERVICE_SUCCESS,
    payload: Service,
})

export const createserviceFail = error => ({
    type: CREATE_SERVICE_FAIL,
    payload: error,
})



export const updateService = Service => ({
    type: UPDATE_SERVICE,
    payload: Service,
})

export const updateserviceSuccess = Service => ({
    type: UPDATE_SERVICE_SUCCESS,
    payload: Service,
})

export const updateserviceFail = error => ({
    type: UPDATE_SERVICE_FAIL,
    payload: error,
})

export const deleteService = id => ({
    type: DELETE_SERVICE,
    payload: id,
})

export const deleteServiceSuccess = id => ({
    type: DELETE_SERVICE_SUCCESS,
    payload: id,
})

export const deleteServiceFail = error => ({
    type: DELETE_SERVICE_FAIL,
    payload: error,
})

export const resetServicesMessages = () => ({
    type: RESET_MESSAGES,
})

export const resetServiceMessages = () => ({
    type: RESET_SERVICE_MESSAGES,
  })
  
  export const resetServiceMessagesSuccess = () => ({
    type: RESET_SERVICE_MESSAGES_SUCCESS,
  })
