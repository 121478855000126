/* Levels */
export const GET_SERVICES = "GET_SERVICES"
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL"


/**
 * CREATE Level
 */
 export const CREATE_SERVICE = "CREATE_SERVICE"
 export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS"
 export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL"
 

/**
 * Edit Level
 */
 export const UPDATE_SERVICE = "UPDATE_SERVICE"
 export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS"
 export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL"
 
 /**
  * Delete SERVICE
  */
 export const DELETE_SERVICE = "DELETE_SERVICE"
 export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
 export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL"

 
/** Reset Messages */
export const RESET_MESSAGES = "RESET_MESSAGES"

export const RESET_SERVICE_MESSAGES_SUCCESS = 'RESET_SERVICE_MESSAGES_SUCCESS';
export const RESET_SERVICE_MESSAGES = 'RESET_SERVICE_MESSAGES';
