import {
  CATEGORY_MESSAGES_RESET,
  RESET_CATEGORY_MESSAGES_SUCCESS,
  CATEGORY_MESSAGES_SUCCESS,
  CREATE_CATEGORY_FAILED,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORY_BY_NAME_FAILED,
  GET_CATEGORY_BY_NAME_REQUEST,
  GET_CATEGORY_BY_NAME_SUCCESS,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "./actionTypes"

export const initialState = {
  loading: false,
  categoriesData: [],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_CATEGORY_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        categoriesData: [...data],
        ...filters,
        loading: false,
        success: "GET",
      }
    case GET_CATEGORY_FAILED:
      return {
        ...state,
        categoriesData: [],
        error: action.payload.message,
        loading: false,
      }
    case GET_CATEGORY_BY_NAME_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_CATEGORY_BY_NAME_SUCCESS:
      return {
        ...state,
        categoriesData: action.payload,
        loading: false,
        success: "SUCCESS",
      }
    case GET_CATEGORY_BY_NAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesData: [...state.categoriesData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case CREATE_CATEGORY_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesData: state.categoriesData.filter(
          category => category.id.toString() !== action.payload.toString()
        ),
        success: "DELETE",
        loading: false,
      }
    case DELETE_CATEGORY_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        success: "UPDATE",
        error: "",
      }
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesData: state.categoriesData.map(category =>
          category.id.toString() === action.payload.id.toString()
            ? { category, ...action.payload }
            : category
        ),
        success: "UPDATE",
        loading: false,
      }
    case UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    case CATEGORY_MESSAGES_RESET:
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      }
    case RESET_CATEGORY_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      }
    default:
      return state
  }
}

export default reducer
