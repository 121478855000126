import React, { useEffect, useState, useMemo } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import { getRetailers, getRetailersById } from "store/actions"
import moment from "moment"

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const StatsRretailer = props => {
  //meta title
  document.title = "Mazaya"
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  const [filter, setFilter] = useState({
    partnerId: "",
    retailerId: "",
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()
  const {
    retailersData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading
  } = useSelector(state => state.retailers)
  const [activeTab, setActiveTab] = useState(moment().format("M"))

  useEffect(() => {
    dispatch(getRetailers(filter))
  }, [dispatch, filter])

  // console.log("retailersData",retailersData)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      let now = new Date()
      let activeMonth = now.setMonth(tab - 1)
      let startDay = new Date(now.getFullYear(), now.getMonth(), 1)
      let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
      setFilter(prevFilter => { return { ...prevFilter, from: moment(startDay).format('Y-MM-DD'), to: moment(lastDay).format('Y-MM-DD') } })
      setActiveTab(tab)
    }
  }
  // Countries Column
  const columns = useMemo(
    () => [

      {
        Header: props.t("Retailer"),
        accessor: "retailerID",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.firstname + " " + cellProps.lastname + " " ||
            props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Logo"),
        accessor: "logo",
        disableFilters: true,
        accessor: cellProps => (
          <>
            {!cellProps.image_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.firstname.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.brand?.imageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Shop Name"),
        accessor: "shopname",
        disableFilters: true,
        accessor: "shopName",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.shopName || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("City"),
        accessor: "parentId",
        disableFilters: true,
        accessor: cellProps => {
          return <>
            {cellProps.city
              ? (currentLanguage == "ar" && cellProps.city?.nameAr) ||
              (currentLanguage == "en" && cellProps.city?.nameEn) ||
              (currentLanguage == "fr" && cellProps.city?.nameFr)
              : cellProps.governorate
                ? (currentLanguage == "ar" && cellProps.governorate?.nameAr) ||
                (currentLanguage == "en" && cellProps.governorate?.nameEn) ||
                (currentLanguage == "fr" && cellProps.governorate?.nameFr)
                : cellProps.country
                  ? (currentLanguage == "ar" && cellProps.country?.nameAr) ||
                  (currentLanguage == "en" && cellProps.country?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.country?.nameFr)
                  : props.t("Not provided")}
          </>
        },
      },
      {
        Header: props.t("Category"),
        accessor: "categoryId",
        disableFilters: true,
        accessor: cellProps => {
          return <>
            {cellProps.category
              ? cellProps.category?.nameEn
              : props.t("Not provided")}
          </>
        },
      },
      {
        Header: props.t("Brand"),
        accessor: "brand",
        disableFilters: true,
        accessor: cellProps => {
          return <>
            {(currentLanguage == "ar" && cellProps.brand?.nameAr) ||
              (currentLanguage == "en" && cellProps.brand?.nameEn) ||
              (currentLanguage == "fr" && cellProps.brand?.nameFr) ||
              props.t("Not provided")}
          </>
        },
      },
      {
        Header: props.t("Retailer Commission %"),
        accessor: "retailerComm",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.comission || props.t("Not provided")}</>
        },
      },
      // {
      //   Header: props.t("Total Sales per month"),
      //   accessor: "",
      //   disableFilters: true,
      //   accessor: cellProps => {
      //     return <></>
      //   },
      // },
      // {
      //   Header: props.t("Total Expenses per month"),
      //   accessor: "",
      //   disableFilters: true,
      //   accessor: cellProps => {
      //     return <></>
      //   },
      // },
      {
        Header: props.t("Last Transaction Date"),
        accessor: "lastTransaction",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.lastTransaction && moment(cellProps.lastTransaction).format("YYYY-MM-DD HH:mm")) || props.t("Not provided")}</>
        },
      },
    ],
    []
  )

  //delete

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handleRetailer = retailer => {
    
    if (!retailer)
      dispatch(getRetailers(filter))
    else
      dispatch(getRetailersById(retailer?.value))
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Retailers")}
            breadcrumbItem={props.t("Retailers Stats")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h1>2022</h1>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    {months.map((month, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={classnames({
                            active: activeTab == index + 1,
                          })}
                          onClick={() => {
                            toggleTab(index + 1)
                          }}
                        >
                          {props.t(month)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </ul>
                  <div className="mt-4">
                    <TableContainer
                      columns={columns}
                      data={retailersData}
                      resetRetailerStatsOption={true}
                      customPageSize={100}
                      setPageNumber={setPageNumber}
                      setLimit={handleLimit}
                      currentPage={currentPage}
                      setRetailer={handleRetailer}
                      retailers={retailersData}
                      isRetailersFilter={true}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      total={total}
                      limit={limit}
                      className="custom-header-css"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(StatsRretailer)
