import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../components/Common/TableContainer"
//redux
import { useSelector, useDispatch } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getBusinessPartner, getEwallet, getRetailers } from "store/actions"

const EwalletStatement = props => {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",
    adminId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })
  const { ewalletData, success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading, } = useSelector(state => state.ewallets)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getRetailers(filter))
    dispatch(getEwallet())
  }, [dispatch, filter])

  const columns2 = useMemo(
    () => [
      {
        Header: props.t("Description"),
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.description || props.t("Not provided")}</>
        },
      },

      {
        Header: props.t("Amount"),
        accessor: "walletTransactions",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.walletTransactions?.amount || props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Solde"),
        accessor: "solde",
        Cell: cellProps => {
          return <>{cellProps.solde || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Transaction Date"),
        accessor: "tdate",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.walletTransactions?.createdAt &&
                moment(
                  new Date(cellProps.walletTransactions?.createdAt)
                ).format("YYYY-MM-DD HH:mm")) ||
                props.t("Not provided")}
            </>
          )
        },
      },
    ],
    []
  )
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <TabPane tabId="3" id="processing">
        <div>
          <TableContainer
            columns={columns2}
            data={ewalletData}
            isGlobalFilter={true}
            isAddOptions={false}
            ActionPay={false}
            filterDate={true}
            customPageSize={100}
            setPageNumber={setPageNumber}
            setLimit={handleLimit}
            nextPage={nextPage}
            previousPage={previousPage}
            currentPage={currentPage}
            total={total}
            limit={limit}
            loading={loading}
            success={success}
          />
        </div>
      </TabPane>
    </React.Fragment>
  )
}

EwalletStatement.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(EwalletStatement)
