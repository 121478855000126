import React, { useEffect } from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css"

//redux

import { withTranslation } from "react-i18next"

import FreeTransactionNumber from "./FreeTransactionNumber"
import AutomaticRenewal from "./AutomaticRenewal"

import { useDispatch, useSelector } from "react-redux"
import { getConfigs } from "store/actions"
import { Card, CardBody } from "reactstrap"

function Setting(props) {
  //meta title
  document.title = "Mazaya"

  const dispatch = useDispatch()
  const { configs } = useSelector(state => state.settings)
  useEffect(() => {
    dispatch(getConfigs())
  }, [])

  //delete order

  return (
    <React.Fragment>
      <div style={{ marginTop: "6rem" }} className="container-fluid">
        <Card>
          <CardBody>
            <FreeTransactionNumber />
            <AutomaticRenewal />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
Setting.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Setting)
