import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import RolesSaga from "./auth/roles/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import adminsSaga from "./admins/saga"
import usersSaga from "./users/saga"
import dashboardSaga from "./dashboard/saga"
import retailersSaga from "./retailer/saga"
import ewalletSaga from "./ewallet/saga"
import transactionsSaga from "./transactions/saga"
import logsSaga from "./audit/log/saga"
import businesspartnersSaga from "./businesspartner/saga"
import categorySaga from "./mdm/category/saga"
import governorateSaga from "./mdm/governorate/saga"
import servicesSaga from "./mdm/service/saga"
import citySaga from "./mdm/city/saga"
import countrySaga from "./mdm/country/saga"
import mmpsSaga from "./mdm/setting/saga"
import brandSaga from "./mdm/brand/saga"
import paymentSaga from "./mdm/paymentMethod/saga"
import payoutSaga from "./accounting/payout/saga"
import payinSaga from "./accounting/payin/saga"
import cardsSaga from "./cards/saga"
import cardTypesSaga from "./cardType/saga"
import parrainageType from "./mdm/parrainageType/saga"
import officesSaga from "./office/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(cardsSaga),
    fork(AuthSaga),
    fork(cardTypesSaga),
    fork(parrainageType),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(RolesSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(ewalletSaga),
    fork(adminsSaga),
    fork(usersSaga),
    fork(dashboardSaga),
    fork(retailersSaga),
    fork(officesSaga),
    fork(categorySaga),
    fork(transactionsSaga),
    fork(logsSaga),
    fork(servicesSaga),
    fork(mmpsSaga),
    fork(brandSaga),
    fork(paymentSaga),
    fork(payoutSaga),
    fork(payinSaga),
    fork(citySaga),
    fork(governorateSaga),
    fork(countrySaga),
    fork(businesspartnersSaga),
  ])
}
