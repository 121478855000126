import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import {
  updateAdmin as onUpdateUser,
  resetAdminsMessages as onReset,
} from "store/admins/actions"
import { withTranslation } from "react-i18next"
import SuccessModal from "components/Common/SuccessModal"
import { upload } from "helpers/api_helper"

const UserProfile = props => {
  //meta title
  document.title = "Mazaya"

  const dispatch = useDispatch()
  const fileRef = useRef()
  const [loadingImage, setLoadingImage] = useState(false)

  const { user, error, loading } = useSelector(state => state.Login)

  const { users, success } = useSelector(state => state.users)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")

  // console.log("orifi", user)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldpassword: "",
      confirmPassword: "",
      password: "",
      email: user?.email || "",
      id: user?.id || "",
      image_url: user?.image_url || "",
    },
    validationSchema: Yup.object({
      // username: Yup.string().required("Please Enter Your UserName"),
      oldpassword: Yup.string(),
      password: Yup.string(),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string()
          .required("Please Enter confirm password")
          .oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
        //otherwise: Yup.string().required()
      }),
    }),
    onSubmit: values => {
      const updateUser = {
        id: user.id,
        ...(values.password && { password: values.password }),
        email: values.email || user?.email,
        image_url: values.image_url || user?.image_url,
      }
      // console.log("updateUser", updateUser)
      // update user
      dispatch(onUpdateUser(updateUser))
      validation.resetForm()
      //setIsEdit(false);
    },
  })
  useEffect(() => {
    // console.log("sucess", success)
    if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Your account has been edited successfully !")
      dispatch(onReset())
    }
  }, [success])

  const handleFileUpload = e => {
    e.preventDefault()
    fileRef?.current?.click()
  }

  const handelImage = event => {
    setLoadingImage(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoadingImage(false)
        validation.setFieldValue("image_url", path)
      })
      .catch(e => {
        setLoadingImage(false)
      })
  }

  return (
    <React.Fragment>
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("User")}
            breadcrumbItem={props.t("Profile")}
          />

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      {!loadingImage &&
                        (!validation?.initialValues?.image_url ? (
                          <div
                            className="avatar-sm mt-3 me-3 external-event"
                            onClick={handleFileUpload}
                          >
                            <span className="avatar-title rounded-circle">
                              {user?.firstname.charAt(0)}
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={handleFileUpload}
                            className="external-event mt-1 me-3"
                          >
                            <img
                              className="rounded-circle avatar-xs min-h-80px min-w-80px"
                              src={
                                validation.values?.image_url ||
                                validation?.initialValues?.image_url
                              }
                              alt=""
                            />
                          </div>
                        ))}
                      {loadingImage && (
                        <Spinner className="text-center mt-3 me-3" size="sm" />
                      )}
                      <Input
                        innerRef={fileRef}
                        name="image_url"
                        type="file"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{user?.firstname + " " + user?.lastname}</h5>
                        <p className="mb-1">{user?.email}</p>
                        <p className="mb-0">Role: {user?.roles[0]?.name}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{props.t("Change Profile")}</h4>

          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">{props.t("Email")}</Label>
                      <Input
                        name="email"
                        label="Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Old Password")}
                      </Label>
                      <Input
                        name="oldpassword"
                        type="password"
                        //placeholder={isEdit ? "If you don't wanna change the password leave this input empty" : ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.oldpassword || ""}
                        invalid={
                          validation.touched.oldpassword &&
                          validation.errors.oldpassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.oldpassword &&
                      validation.errors.oldpassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.oldpassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("New Password")}
                      </Label>
                      <Input
                        name="password"
                        type="password"
                        //placeholder={isEdit ? "If you don't wanna change the password leave this input empty" : ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Confirm Password")}
                      </Label>
                      <Input
                        name="confirmPassword"
                        type="password"
                        //placeholder={isEdit ? "If you don't wanna change the password leave this input empty" : ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmPassword || ""}
                        invalid={
                          validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.confirmPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        {"Update"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(UserProfile))
