import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import {
  TabPane,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../components/Common/TableContainer"
//redux
import { useSelector, useDispatch } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getBusinessPartner, getEwallet, getRetailers } from "store/actions"

const UserEarnings = props => {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",
    adminId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })
  const { ewalletData } = useSelector(state => state.ewallets)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getRetailers(filter))
    dispatch(getEwallet())
  }, [dispatch, filter])

 
  const columns3 = useMemo(
    () => [
      {
        Header: props.t("Category Commission"),
        accessor: "commission",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.commission || props.t("Not provided")}</>
        },
      },

      {
        Header: props.t("Total Amount"),
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.amount || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Tax"),
        accessor: "tax",
        Cell: cellProps => {
          return <>{cellProps.tax || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Service Charge"),
        accessor: "service",
        Cell: cellProps => {
          return <>{cellProps.service || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Amount Payable"),
        accessor: "pamount  ",
        Cell: cellProps => {
          return <>{cellProps.pamount || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Transaction Date"),
        accessor: "tdate",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.walletTransactions?.createdAt &&
                moment(
                  new Date(cellProps.walletTransactions?.createdAt)
                ).format("YYYY-MM-DD HH:mm")) ||
                props.t("Not provided")}
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TabPane tabId="4" id="user">
        <div>
          <TableContainer
            columns={columns3}
            data={ewalletData}
            isGlobalFilter={true}
            isAddOptions={false}
            ActionPay={false}
            filterDate={true}
            customPageSize={100}
          />
        </div>
      </TabPane>
    </React.Fragment>
  )
}

UserEarnings.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(UserEarnings)
