import {
  GET_ADMIN_PROFILE_FAIL,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMINS,
  GET_ADMINS_FAIL,
  GET_ADMINS_SUCCESS,
  ADD_NEW_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  RESET_ADMINS_MESSAGES_SUCCESS,
  RESET_ADMINS_MESSAGES,
  GET_ADMIN_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
} from "./actionTypes"

export const getAdmins = filter => ({
  type: GET_ADMINS,
  payload: filter,
})

export const getAdminsSuccess = admins => ({
  type: GET_ADMINS_SUCCESS,
  payload: admins,
})

export const addNewAdmin = user => ({
  type: ADD_NEW_ADMIN,
  payload: user,
})

export const addAdminSuccess = user => ({
  type: ADD_ADMIN_SUCCESS,
  payload: user,
})

export const addAdminFail = error => ({
  type: ADD_ADMIN_FAIL,
  payload: error,
})

export const getAdminsFail = error => ({
  type: GET_ADMINS_FAIL,
  payload: error,
})

export const getAdminProfile = id => ({
  type: GET_ADMIN_PROFILE,
  payload: id,
})

export const getAdminProfileSuccess = userProfile => ({
  type: GET_ADMIN_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getAdminProfileFail = error => ({
  type: GET_ADMIN_PROFILE_FAIL,
  payload: error,
})

export const updateAdmin = user => ({
  type: UPDATE_ADMIN,
  payload: user,
})

export const updateAdminSuccess = user => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload: user,
})

export const updateAdminFail = error => ({
  type: UPDATE_ADMIN_FAIL,
  payload: error,
})
export const updateProfil = Profil => ({
  type: UPDATE_PROFILE,
  payload: Profil,
})

export const updateProfilSuccess = Profil => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: Profil,
})

export const updateProfilFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
})

export const deleteAdmin = user => ({
  type: DELETE_ADMIN,
  payload: user,
})

export const deleteAdminSuccess = id => ({
  type: DELETE_ADMIN_SUCCESS,
  payload: id,
})

export const deleteAdminFail = error => ({
  type: DELETE_ADMIN_FAIL,
  payload: error,
})

export const resetAdminsMessages = () => ({
  type: RESET_ADMINS_MESSAGES,
})

export const resetAdminsMessagesSuccess = () => ({
  type: RESET_ADMINS_MESSAGES_SUCCESS,
})
