import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

// Column
import { UserName, ArabicName, EnName, Categorycomission } from "./CategoryCol"
import {
  getMDMCategories,
  updateMDMCategory,
  resetCategoryMessages,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import ShowCategory from "./ShowCategory"
import { checkPermission } from "helpers/functions"
import SubCategoryModal from "./SubCategoryModal"

const SubCategory = props => {
  const {
    categoriesData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.categories)
  const userRoles = useSelector(state => state.Login.user?.roles)

  //meta title
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [category, setCategory] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const [show, setShow] = useState(false)

  const handleCustomerClick = arg => {
    const category = arg

    setCategory({
      id: category.id,
      nameEn: category.nameEn,
      nameFr: category.nameFr,
      nameAr: category.nameAr,
      parentId: category.parentId,
    })

    setIsEdit(true)
    toggle()
  }

  const toggleShow = () => {
    setShow(!show)
  }

  // Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "nameEn",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              // onClick={() => {
              //   toggleShow()
              //   setCategory(cellProps)
              // }}
            >
              {cellProps.nameEn}
            </div>
          )
        },
      },
      {
        Header: props.t("Parent Category"),
        accessor: "parent",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="external-event">{cellProps.parent?.nameEn}</div>
          )
        },
      },

      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, [
                "MasterData",
                "SubCategory",
                "Update",
              ]) && (
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const CategoryData = cellProps.row.original
                    handleCustomerClick(CategoryData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </Link>
              )}
              {checkPermission(userRoles, [
                "MasterData",
                "SubCategory",
                "Delete",
              ]) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const CategoryData = cellProps.row.original
                    onClickDelete(CategoryData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCategory(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = category => {
    setCategory(category)
    setDeleteModal(true)
  }

  const handleDeleteCategory = () => {
    if (category.id) {
      dispatch(updateMDMCategory({ id: category.id, status: "inactive" }))
      setDeleteModal(false)

      dispatch(getMDMCategories())
    }
  }

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Category created successfully !")
      dispatch(resetCategoryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("successfully !")
      dispatch(resetCategoryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getMDMCategories(filter))
    }
  }, [dispatch, filter])

  useEffect(() => {
    setCategoryList(categoriesData)
  }, [categoriesData])

  useEffect(() => {
    if (!isEmpty(categoriesData)) {
      setCategoryList(categoriesData)
    }
  }, [categoriesData])

  const handleCategoryClicks = () => {
    setCategoryList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ShowCategory modal={show} toggle={toggleShow} category={category} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("MDM")}
            breadcrumbItem={props.t("Sub-Categories List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={categoriesData.filter(c => c.parentId != null)}
                    //isGlobalFilter={true}

                    isAddCustList={checkPermission(userRoles, [
                      "MasterData",
                      "SubCategory",
                      "Create",
                    ])}
                    handelAddClick={handleCategoryClicks}
                    customPageSize={100}
                    setLimit={handleLimit}
                    setPageNumber={setPageNumber}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <SubCategoryModal
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    category={category}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SubCategory)
