import usFlag from "../assets/images/flags/us.jpg"
import frFLag from "../assets/images/flags/french.jpg"
import tnFlag from "../assets/images/flags/tunisia.png"

const languages = {
  ar: {
    label: "العربية",
    flag: tnFlag,
  },
  fr: {
    label: "Français",
    flag: frFLag,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
