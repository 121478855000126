import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { upload } from "helpers/api_helper"
import { useSelector, useDispatch } from "react-redux"
import PhoneInput from "react-phone-input-2"
import {
  createCard,
  updateCard,
  getCardType,
  getCard,
  getMDMPayment,
  getMDMCountries,
} from "store/actions"
import { cleanUser, addNewUser as onAddNewUser } from "store/users/actions"
import { getUsers } from "store/users/actions"
import { withTranslation } from "react-i18next"
import { DatePicker } from "reactstrap-date-picker"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import moment from "moment"
import copy from "../../assets/images/copy.svg"

const CardModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, card, isEdit } = props
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const { users, createdUser } = useSelector(state => state.users)
  const { user } = useSelector(state => state.Login)
  const { cardTypeData } = useSelector(state => state.cardTypes)
  const { paymentData } = useSelector(state => state.payments)
  const { countriesData } = useSelector(state => state.countries)

  const [showAddCustomer, setShowAddCustomer] = useState(false)
  const [phonePicker, setPhonePicker] = useState("")
  const [newCustomer, setNewCustomer] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    email: "",
    cin: "",
    birthdate: "",
    countryId: "",
  })

  useEffect(() => {
    dispatch(getUsers({ page: 1, limit: 1000, accountType: "customer" }))
    dispatch(getCardType({ page: 1, limit: 1000, status: "active" }))
    dispatch(getMDMPayment({ page: 1, limit: 1000, status: "active" }))
    dispatch(getMDMCountries())
  }, [dispatch])

  const currentLanguage = localStorage.getItem("MAZAYA_CURRENCY")

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (card && card.id) || "",
      code: (card && card.code) || "",
      customerId: (card && card.customerId) || "",
      price: (card && card.price) || "",
      createdById: (card && card.createdById) || null,
      officeId: (card && card.officeId) || null,
      cardTypeId: (card && card.cardTypeId) || "",
      paymentMethodId: (card && card.paymentMethodId) || "",
      paymentStatus: (card && card.paymentStatus) || "",
      currency: (card && card.currency) || currentLanguage,
      validUntil:
        (card && card.validUntil) ||
        moment().add(1, "year").format("YYYY-MM-DD"),
    },
    validationSchema: Yup.object({
      // customerId: Yup.string().required(
      //   props.t("Please Enter select the customer")
      // ),
      price: Yup.string().required(props.t("Please Enter card price")),
      cardTypeId: Yup.string().required(props.t("Please select card type")),
      paymentStatus: Yup.string().required(
        props.t("Please select payment Status")
      ),
      // validUntil: Yup.string().required(props.t("Please select card validity")),
    }),

    onSubmit: values => {
      if (isEdit) {
        const updatedCard = {
          id: card.id,
          customerId: parseInt(values.customerId),
          cardTypeId: parseInt(values.cardTypeId),
          paymentMethodId: parseInt(values.paymentMethodId),
          validUntil: moment(values.validUntil).format("YYYY-MM-DD"),
          price: values.price,
          createdById: user?.id,
          officeId: user?.officeId,
          paymentStatus: values.paymentStatus,
          currency: values.currency,
        }

        // update order
        dispatch(updateCard(updatedCard))
        validation.resetForm()
      } else {
        const newCard = {
          customerId: parseInt(values["customerId"]),
          cardTypeId: parseInt(values["cardTypeId"]),
          paymentMethodId: parseInt(values["paymentMethodId"]),
          validUntil: moment(values["validUntil"]).format("YYYY-MM-DD"),
          price: values["price"],
          createdById: user?.id,
          officeId: user?.officeId,
          status: "active",
          paymentStatus: values["paymentStatus"],
          currency: currentLanguage,
          holders: [
            { customerId: parseInt(values["customerId"]), type: "owner" },
          ],
        }

        // console.log("newCard", newCard)
        // save new card
        dispatch(createCard(newCard))
        validation.resetForm()
        // dispatch(cleanUser())
      }
      toggle()
    },
  })

  useEffect(() => {
    if (createdUser?.id) validation.setFieldValue("customerId", createdUser?.id)
  }, [createdUser])

  const onChangeCardType = event => {
    event.stopPropagation()
    const type = cardTypeData.find(
      item => item.id === parseInt(event.target.value)
    )
    const price =
      currentLanguage == "TND"
        ? type?.price_TND
        : currentLanguage == "DZD"
        ? type?.price_DZD
        : type?.price_LYD
    validation.setFieldValue("price", price || 0)
  }

  const copyToClipBoardHandler = () => {
    navigator.clipboard.writeText(card?.code || "")
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  const handleChangeCoutry = async e => {
    validation.handleChange(e)
    let coutry = countriesData.find(coutry => coutry.id == e.target.value)
    if (coutry?.iso_code == "TN") {
      setPhonePicker("tn")
    } else if (coutry?.iso_code == "LY") {
      setPhonePicker("ly")
    }

    setNewCustomer({
      ...newCustomer,
      countryId: coutry.id,
    })
  }

  const handleAddCustomer = async () => {
    const { firstname, lastname, phone, email, cin, birthdate, countryId } =
      newCustomer

    dispatch(
      onAddNewUser(
        {
          firstname,
          lastname,
          phone,
          email,
          password: phone,
          accountType: "customer",
          status: "active",
          freeQrCode: "false",
          cin,
          birthdate,
          countryId,
        },
        validation.setFieldValue
      )
    )

    console.log("createdUser:", createdUser)

    console.error("Users data is not populated yet.")

    setShowAddCustomer(false)
    setNewCustomer({ firstname: "", lastname: "", phone: "", email: "" }) // Reset form state
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Card") : props.t("Add Card")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              {isEdit && card?.id ? (
                <div className="mb-3 position-relative">
                  <Label className="form-label">{props.t("Code")}</Label>
                  <Input
                    readOnly
                    name="code"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                    className="external-event"
                    style={{ backgroundColor: "#eff2f7" }}
                    onClick={copyToClipBoardHandler}
                  />
                  <img
                    src={copy}
                    alt=""
                    height="24"
                    className="position-absolute external-event"
                    style={{ right: 10, top: 34 }}
                  />

                  {copied ? (
                    <span className="text-success">
                      Text copied to clipboard !
                    </span>
                  ) : null}

                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
              ) : null}
              <div className="mb-3">
                <Label className="form-label">{props.t("Card Type")}</Label>
                <Input
                  name="cardTypeId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={event => {
                    onChangeCardType(event)
                    validation.handleChange(event)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.cardTypeId || ""}
                  invalid={
                    validation.touched.cardTypeId &&
                    validation.errors.cardTypeId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {cardTypeData
                    ?.filter(item => item.name !== "free")
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name || "----"}
                      </option>
                    ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.cardTypeId &&
                validation.errors.cardTypeId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.cardTypeId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Customer")}</Label>
                <Input
                  name="customerId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.customerId || ""}
                  invalid={
                    validation.touched.customerId &&
                    validation.errors.customerId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {users?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.firstname + " " + item.lastname}
                    </option>
                  ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.customerId &&
                validation.errors.customerId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.customerId}
                  </FormFeedback>
                ) : null}
                {isEdit && card?.id ? null : (
                  <button
                    type="button"
                    className="btn btn-primary mt-0"
                    onClick={() => setShowAddCustomer(prev => !prev)}
                  >
                    {props.t("Add New Customer")}
                  </button>
                )}
              </div>
              {showAddCustomer && (
                <div className="add-customer-form mb-3">
                  <Label className="form-label">{props.t("First Name")}</Label>
                  <Input
                    type="text"
                    value={newCustomer.firstname}
                    onChange={e =>
                      setNewCustomer({
                        ...newCustomer,
                        firstname: e.target.value,
                      })
                    }
                  />
                  <Label className="form-label">{props.t("Last Name")}</Label>
                  <Input
                    type="text"
                    value={newCustomer.lastname}
                    onChange={e =>
                      setNewCustomer({
                        ...newCustomer,
                        lastname: e.target.value,
                      })
                    }
                  />
                  <Label className="form-label">{props.t("Cin")}</Label>
                  <Input
                    type="text"
                    value={newCustomer.cin}
                    onChange={e =>
                      setNewCustomer({
                        ...newCustomer,
                        cin: e.target.value,
                      })
                    }
                  />

                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    type="email"
                    value={newCustomer.email}
                    onChange={e =>
                      setNewCustomer({
                        ...newCustomer,
                        email: e.target.value,
                      })
                    }
                  />
                  <Label className="form-label">{props.t("Birthday")}</Label>
                  <DatePicker
                    id="card-validity-from-datepicker"
                    value={newCustomer.birthdate}
                    onChange={(v, f) =>
                      setNewCustomer({
                        ...newCustomer,
                        birthdate: moment(v).format("YYYY-MM-DD"),
                      })
                    }
                  />
                  <Label className="form-label">{props.t("Country")}</Label>
                  <Input
                    type="select"
                    name="parentId"
                    className="form-select"
                    onChange={e => handleChangeCoutry(e)}
                    // onChange={e =>
                    //   setNewCustomer({
                    //     ...newCustomer,
                    //     countryId: e.target.value,
                    //   })
                    // }
                    value={newCustomer.countryId}
                  >
                    <option key="empty" disabled></option>
                    {countriesData
                      .filter(country => country.status != "archived")
                      .map(item => (
                        <option key={item.id} value={item.id}>
                          {item.nameEn}
                        </option>
                      ))}
                  </Input>

                  <PhoneInput
                    country={phonePicker}
                    name="phone"
                    value={newCustomer.phone}
                    placeholder=""
                    onChange={phone =>
                      setNewCustomer({
                        ...newCustomer,
                        phone,
                      })
                    }
                  />
                  {/* <Input
                    type="text"
                    value={newCustomer.phone}
                    onChange={e =>
                      setNewCustomer({
                        ...newCustomer,
                        phone: e.target.value,
                      })
                    }
                  /> */}
                  <button
                    type="button"
                    className="btn btn-success mt-3"
                    onClick={handleAddCustomer}
                  >
                    {props.t("Add Customer")}
                  </button>
                </div>
              )}
              <div className="mb-3">
                <Label className="form-label">
                  {`${props.t("Price")} ( ${
                    validation.values.currency || currentLanguage
                  } )`}
                </Label>
                <Input
                  name="price"
                  type="number"
                  disabled
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price || ""}
                  invalid={
                    validation.touched.price && validation.errors.price
                      ? true
                      : false
                  }
                />
                {validation.touched.price && validation.errors.price ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Payment Method")}
                </Label>
                <Input
                  name="paymentMethodId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.paymentMethodId || ""}
                  invalid={
                    validation.touched.paymentMethodId &&
                    validation.errors.paymentMethodId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {paymentData?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nameEn || "-----"}
                    </option>
                  ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.paymentMethodId &&
                validation.errors.paymentMethodId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.paymentMethodId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Payment Status")}
                </Label>
                <Input
                  name="paymentStatus"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={e => validation.handleChange(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.paymentStatus || ""}
                  invalid={
                    validation.touched.paymentStatus &&
                    validation.errors.paymentStatus
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                </Input>

                {validation.touched.paymentStatus &&
                validation.errors.paymentStatus ? (
                  <FormFeedback type="invalid">
                    {validation.errors.paymentStatus}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Valid Until")}</Label>
                <DatePicker
                  id="card-validity-from-datepicker"
                  value={validation.values.validUntil}
                  onChange={(v, f) =>
                    validation.setFieldValue(
                      "validUntil",
                      moment(v).format("YYYY-MM-DD")
                    )
                  }
                />
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.errors.validUntil ? (
                  <FormFeedback type="invalid">
                    {validation.errors.validUntil}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(CardModal)
