import {
  createOffice,
  createRetailer,
  getOffice,
  getOffices,
  getRetailers,
  getRetailersByName,
  getRetailerstats,
  getUser,
  updateOffice,
  updateRetailer,
} from "helpers/api_helper"
import { all, put, takeEvery } from "redux-saga/effects"
// import Repository from "../../repositories/RetailersRepository";
import {
  createRetailerFailed,
  createRetailerSuccess,
  deleteRetailerFailed,
  resetRetailersMessagesSuccess,
  deleteRetailerSuccess,
  getRetailersFailed,
  getRetailersSuccess,
  getRetailersByNameFailed,
  getRetailersByNameSuccess,
  updateRetailerFailed,
  updateRetailerSuccess,
  getRetailersStatsFailed,
  getRetailersStatsSuccess,
  getOfficesSuccess,
  getOfficesFailed,
  getOfficesById,
  createOfficeFailed,
  deleteOfficeSuccess,
  deleteOfficeFailed,
  resetOfficesMessagesSuccess,
  updateOfficeFailed,
} from "./actions"
import {
  CREATE_OFFICE_REQUEST,
  DELETE_OFFICE_REQUEST,
  GET_OFFICE_BY_ID_REQUEST,
  GET_OFFICES_REQUEST,
  RESET_OFFICES_MESSAGES,
  UPDATE_OFFICE_REQUEST,
} from "./actionTypes"

function* getOfficesSaga({ payload }) {
  try {
    const response = yield getOffices(payload)
    const { data, ...filters } = response
    yield put(getOfficesSuccess(response))
  } catch (e) {
    console.log("error-----", e)
    yield put(getOfficesFailed(e.response?.status))
  }
}

function* getOfficeByIdSaga({ payload }) {
  try {
    //const response = yield getUser(payload);
    yield put(getOfficesById(payload))
  } catch (e) {}
}

function* createOfficeSaga({ payload: retailer }) {
  // console.log("ree", retailer)
  try {
    // const result = yield new Repository().createRetailer(payload);
    const { data } = yield createOffice(retailer)
    const response = yield getOffices({ limit: 20, page: 1 })
    yield put(getOfficesSuccess(response))
  } catch (e) {
    // console.log(e.response)
    yield createOfficeFailed(e)
  }
}

function* deleteOfficeSaga({ payload: retailers }) {
  try {
    // const result = yield new Repository().deleteRetailer(payload);
    //const data = yield new Repository().getRetailers();
    yield put(deleteOfficeSuccess(retailers))
    yield put(getOfficesSuccess(payload))
  } catch (e) {
    console.log("error delete:", e)
    yield put(deleteOfficeFailed(e))
  }
}

function* updateOfficeSaga({ payload: retailer }) {
  try {
    const { id, ...updatedRetailer } = retailer
    // console.log("updatedRetailer",updatedRetailer)
    // console.log("retailer",retailer)
    const data = yield updateOffice(id, updatedRetailer)
    // console.log("data",data)
    const response = yield getOffices({ limit: 20, page: 1 })
    yield put(getOfficesSuccess(response))
  } catch (e) {
    console.log("error update:", e.response)
    yield put(updateOfficeFailed(e))
  }
}

function* resetOfficesMessagesSaga() {
  yield put(resetOfficesMessagesSuccess())
}

export default function* officesSaga() {
  yield all([
    takeEvery(GET_OFFICES_REQUEST, getOfficesSaga),
    takeEvery(GET_OFFICE_BY_ID_REQUEST, getOfficeByIdSaga),
    takeEvery(CREATE_OFFICE_REQUEST, createOfficeSaga),
    takeEvery(DELETE_OFFICE_REQUEST, deleteOfficeSaga),
    takeEvery(UPDATE_OFFICE_REQUEST, updateOfficeSaga),
    takeEvery(RESET_OFFICES_MESSAGES, resetOfficesMessagesSaga),
  ])
}
