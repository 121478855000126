import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Roles from "./auth/roles/reducer"

//Calendar
import calendar from "./calendar/reducer"

//invoices
import retailers from "./retailer/reducer"
import offices from "./office/reducer"
import ewallets from "./ewallet/reducer"
import transactions from "./transactions/reducer"
import logs from "./audit/log/reducer"
import payout from "./accounting/payout/reducer"
import payin from "./accounting/payin/reducer"
import businesspartners from "./businesspartner/reducer"
import categories from "./mdm/category/reducer"
import services from "./mdm/service/reducer"
import cities from "./mdm/city/reducer"
import countries from "./mdm/country/reducer"
import governorates from "./mdm/governorate/reducer"
import settings from "./mdm/setting/reducer"
import brands from "./mdm/brand/reducer"
import payments from "./mdm/paymentMethod/reducer"
//contacts
import admins from "./admins/reducer"
import users from "./users/reducer"
//Dashboard
import Dashboard from "./dashboard/reducer"
import cards from "./cards/reducer"
import cardTypes from "./cardType/reducer"
import parrainageTypes from "./mdm/parrainageType/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Roles,
  cards,
  cardTypes,
  parrainageTypes,
  services,
  cities,
  ewallets,
  brands,
  payout,
  admins,
  payin,
  countries,
  settings,
  payments,
  governorates,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  users,
  retailers,
  transactions,
  logs,
  businesspartners,
  categories,
  Dashboard,
  offices,
})

export default rootReducer
