import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createMDMBrandFailed,
  createMDMBrandSuccess,
  deleteMDMBrandFailed,
  deleteMDMBrandSuccess,
  getMDMBrandFailed,
  getMDMBrandSuccess,
  updateMDMBrandFailed,
  updateMDMBrandSuccess,
  resetBrandMessagesSuccess,
} from "./actions"
import {
  BRAND_MESSAGES_RESET,
  CREATE_BRAND_REQUEST,
  DELETE_BRAND_REQUEST,
  GET_BRAND_BY_NAME_REQUEST,
  GET_BRAND_REQUEST,
  RESET_BRAND_MESSAGES,
  UPDATE_BRAND_REQUEST,
} from "./actionTypes"

import {
  getBrand,
  postBrand,
  postContract,
  putBrand,
  putContract,
} from "helpers/api_helper"

function* getBrandSaga({ payload }) {
  try {
    const response = yield getBrand(
      payload || { page: 1, limit: 100, status: "active" }
    )
    yield put(getMDMBrandSuccess(response))
  } catch (e) {
    yield put(getMDMBrandFailed(e))
  }
}

function* getBrandBynameSaga() {}

function* createBrandSaga({ payload: { brand, contract } }) {
  try {
    const brandData = yield postBrand(brand)
    console.log("---new brand---", brandData)
    if (contract?.from && contract?.to) {
      const contractData = yield postContract({
        ...contract,
        brandId: brandData.id,
      })
    }
    const response = yield getBrand({ limit: 100, page: 1, status: "active" })
    yield put(getMDMBrandSuccess(response))
  } catch (e) {
    yield put(createMDMBrandFailed(e))
  }
}

function* deleteBrandSaga({ payload }) {
  try {
    console.log("delee", payload)
    // const{id, ...updatedid}= idcategory
    // console.log("delete",idcategory, updatedid  )
    // console.log("delete category",id   )
    // const response = yield deleteCategories(id);
    //yield put(deleteMDMBrandSuccess(id))
  } catch (e) {
    yield put(deleteMDMBrandFailed(e))
  }
}

function* updateBrandSaga({ payload: { brand, contract } }) {
  try {
    // separer l id & objet category
    const { id, ...updatedBrand } = brand
    const { id: contractId, ...updatedContract } = contract
    const data = yield putBrand(id, updatedBrand)
    if (contract?.id) {
      console.log("updatedContract++++++", updatedContract)
      const contractData = yield putContract(contractId, updatedContract)
    } else if (contract?.from && contract?.to) {
      const contractData = yield postContract({ contract })
    }
    const response = yield getBrand({ limit: 100, page: 1, status: "active" })
    yield put(getMDMBrandSuccess(response))
  } catch (e) {
    yield put(updateMDMBrandFailed(e))
  }
}

function* resetBrandMessagesSaga() {
  yield put(resetBrandMessagesSuccess())
}

export default function* brandSaga() {
  yield all([
    takeEvery(GET_BRAND_REQUEST, getBrandSaga),
    takeEvery(GET_BRAND_BY_NAME_REQUEST, getBrandBynameSaga),
    takeEvery(CREATE_BRAND_REQUEST, createBrandSaga),
    takeEvery(DELETE_BRAND_REQUEST, deleteBrandSaga),
    takeEvery(RESET_BRAND_MESSAGES, resetBrandMessagesSaga),
    takeEvery(UPDATE_BRAND_REQUEST, updateBrandSaga),
  ])
}
