import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createCardTypeFailed,
  createCardTypeSuccess,
  deleteCardTypeFailed,
  deleteCardTypeSuccess,
  getCardTypeFailed,
  getCardTypeSuccess,
  updateCardTypeFailed,
  resetCardTypeMessagesSuccess,
} from "./actions"
import {
  CARDTYPE_MESSAGES_RESET,
  CREATE_CARDTYPE_REQUEST,
  DELETE_CARDTYPE_REQUEST,
  GET_CARDTYPE_BY_NAME_REQUEST,
  GET_CARDTYPE_REQUEST,
  RESET_CARDTYPE_MESSAGES,
  UPDATE_CARDTYPE_REQUEST,
} from "./actionTypes"

import { getCardTypes, postCardTypes, putCardTypes } from "helpers/api_helper"

function* getCardTypeSaga({ payload }) {
  try {
    const response = yield getCardTypes(
      payload || { page: 1, limit: 100, status: "active" }
    )
    yield put(getCardTypeSuccess(response))
  } catch (e) {
    yield put(getCardTypeFailed(e))
  }
}

function* getCardTypeBynameSaga() {}

function* createCardTypeSaga({ payload: cardType }) {
  try {
    const cardTypeData = yield postCardTypes(cardType)

    const response = yield getCardType({
      limit: 100,
      page: 1,
      status: "active",
    })
    yield put(getCardTypeSuccess(response))
  } catch (e) {
    yield put(createCardTypeFailed(e))
  }
}

function* deleteCardTypeSaga({ payload }) {
  try {
    console.log("delee", payload)
    // const{id, ...updatedid}= idcategory
    // console.log("delete",idcategory, updatedid  )
    // console.log("delete category",id   )
    // const response = yield deleteCategories(id);
    //yield put(deleteCardTypeSuccess(id))
  } catch (e) {
    yield put(deleteCardTypeFailed(e))
  }
}

function* updateCardTypeSaga({ payload: cardType }) {
  try {
    // separer l id & objet category
    const { id, ...updatedCardType } = cardType
    const data = yield putCardTypes(id, updatedCardType)
    const response = yield getCardTypes({
      limit: 100,
      page: 1,
      status: "active",
    })
    yield put(getCardTypeSuccess(response))
  } catch (e) {
    yield put(updateCardTypeFailed(e))
  }
}

function* resetCardTypeMessagesSaga() {
  yield put(resetCardTypeMessagesSuccess())
}

export default function* cardTypeSaga() {
  yield all([
    takeEvery(GET_CARDTYPE_REQUEST, getCardTypeSaga),
    takeEvery(GET_CARDTYPE_BY_NAME_REQUEST, getCardTypeBynameSaga),
    takeEvery(CREATE_CARDTYPE_REQUEST, createCardTypeSaga),
    takeEvery(DELETE_CARDTYPE_REQUEST, deleteCardTypeSaga),
    takeEvery(RESET_CARDTYPE_MESSAGES, resetCardTypeMessagesSaga),
    takeEvery(UPDATE_CARDTYPE_REQUEST, updateCardTypeSaga),
  ])
}
