
/**filter */
export const GET_PARRAINAGE_BY_NAME_SUCCESS = "GET_PARRAINAGE_BY_NAME_SUCCESS";
export const GET_PARRAINAGE_BY_NAME_REQUEST = "GET_PARRAINAGE_BY_NAME_REQUEST";
export const GET_PARRAINAGE_BY_NAME_FAILED = "GET_PARRAINAGE_BY_NAME_FAILED";

/**Get */
export const GET_PARRAINAGE_SUCCESS = 'GET_PARRAINAGE_SUCCESS';
export const GET_PARRAINAGE_REQUEST = 'GET_PARRAINAGE_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_PARRAINAGE_FAILED = 'GET_PARRAINAGE_FAILED';

/**Create */
export const CREATE_PARRAINAGE_SUCCESS = 'CREATE_PARRAINAGE_SUCCESS';
export const CREATE_PARRAINAGE_FAILED = 'CREATE_PARRAINAGE_FAILED';
export const CREATE_PARRAINAGE_REQUEST = 'CREATE_PARRAINAGE_REQUEST';
/**Delete */
export const DELETE_PARRAINAGE_SUCCESS = 'DELETE_PARRAINAGE_SUCCESS';
export const DELETE_PARRAINAGE_FAILED = 'DELETE_PARRAINAGE_FAILED';
export const DELETE_PARRAINAGE_REQUEST = 'DELETE_PARRAINAGE_REQUEST';
/**Update */
export const UPDATE_PARRAINAGE_SUCCESS = 'UPDATE_PARRAINAGE_SUCCESS';
export const UPDATE_PARRAINAGE_FAILED = 'UPDATE_PARRAINAGE_FAILED';
export const UPDATE_PARRAINAGE_REQUEST = 'UPDATE_PARRAINAGE_REQUEST';

export const PARRAINAGE_MESSAGES_RESET = 'PARRAINAGE_MESSAGES_RESET';
export const PARRAINAGE_MESSAGES_SUCCESS = 'PARRAINAGE_MESSAGES_SUCCESS';
export const RESET_PARRAINAGE_MESSAGES_SUCCESS = 'RESET_PARRAINAGE_MESSAGES_SUCCESS';
export const RESET_PARRAINAGE_MESSAGES = 'RESET_PARRAINAGE_MESSAGES';

