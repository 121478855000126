import React, { useEffect, useState, useMemo, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"
import BuisnessPartnerModal from "./BuisnessPartnerModal"
import { getBusinessPartner, getPartnerStats, updateBusinessPartner } from "store/actions"
import moment from "moment"
import { withTranslation } from "react-i18next"
import MiniCards from "pages/Dashboard/mini-cards"
import { map } from "lodash"
import { checkPermission } from "helpers/functions"

const BusinessPartner = props => {
  const {
    BusinessPartnerData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.businesspartners)
  const {PartnerStats} = useSelector(state => state.businesspartners)
  const userRoles = useSelector(state => state.Login.user?.roles)
  //meta title
  document.title = "Mazaya"
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    partnerId: "",
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [buisnessPartner, setBuisnessPartner] = useState(null)
  const componentRef = useRef();

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ])
  useEffect(() => {
    if(BusinessPartnerData != undefined){
      setMiniCards([
        {
          title: props.t("Total joinings"),
          iconClass: "bx-check-circle",
          text: PartnerStats.totalJoinings,
        },
        {
          title: props.t("Today joinings"),
          iconClass: "dripicons-user-group",
          text:  PartnerStats?.todayJoinings,
        },
      ]
      )
    }
  }, [PartnerStats])
  const handleCustomerClick = arg => {
    const buisnessPartner = arg
    setBuisnessPartner({
      id: buisnessPartner.id,
      firstname: buisnessPartner.firstname,
      phone: buisnessPartner.phone,
      email: buisnessPartner.email,
      address: buisnessPartner.address,
      lastname: buisnessPartner.lastname,
    })

    setIsEdit(true)
    toggle()
  }

  // Business Partner Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Full name"),
        accessor: "firstname",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() =>{ history.push(`/partner-profile/${cellProps.id}`)}} //if(checkPermission(userRoles, ["Partner", "Partner", "Show"]))
            >
              {cellProps.firstname + " " + cellProps.lastname + " " ||
                props.t("Not provided")}
              ({cellProps.username})
            </div>
          )
        },
      },
      {
        Header: props.t("Parent ID"),
        accessor: "parrainId",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.parrainId || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Nr. L1"),
        accessor: "levels",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.levels[0]?.count || "---"}</>
        },
      },
      {
        Header: props.t("Nr. L2"),
        accessor: "levels",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.levels[1]?.count || "---"}</>
        },
      },
      {
        Header: props.t("Nr. L3"),
        accessor: "levels",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.levels[2]?.count || "---"}</>
        },
      },
      {
        Header: props.t("Nr. L4"),
        accessor: "levels",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.levels[3]?.count || "---"}</>
        },
      },
      {
        Header: props.t("Member Since"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD") ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Last referral date"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.lastReferral &&
                moment(new Date(cellProps.lastReferral)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("---")}
            </>
          )
        },
      },
      {
        Header: props.t("Last purchase date"),
        accessor: "lastTransaction",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.lastTransaction &&
                moment(new Date(cellProps.lastTransaction)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("---")}
            </>
          )
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="text-center">
              {checkPermission(userRoles, ["Partner", "Partner", "Update"]) && 
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const customerData = cellProps.row.original
                  handleCustomerClick(customerData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>}
              {checkPermission(userRoles, ["Partner", "Partner", "Delete"]) && 
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setBuisnessPartner(null)
    } else {
      setModal(true)
    }
  }

  //delete Business Partner
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = buisnessPartner => {
    setBuisnessPartner(buisnessPartner)
    setDeleteModal(true)
  }

  const handleDeleteCustomer = () => {
    if (buisnessPartner.firstname) {
      dispatch(
        updateBusinessPartner({ id: buisnessPartner.id, status: "archived" })
      )
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getPartnerStats())
  }, [dispatch, filter])
  
  const handleCustomerClicks = () => {
    setBuisnessPartner(null)
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  const handlePartner = id => {
    setFilter(prevFilter => {
      return { ...prevFilter, partnerId: id }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <BuisnessPartnerModal
        modal={modal}
        isEdit={isEdit}
        toggle={toggle}
        buisnessPartner={buisnessPartner}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Buisness Partners")}
            breadcrumbItem={props.t("Business Partner List")}
          />
          <Row>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    userRef={componentRef}
                    // isPrintOption={checkPermission(userRoles, ["Partner", "Partner", "Print"])}
                    setPartner={handlePartner}
                    data={BusinessPartnerData}
                    isExcelPartner={checkPermission(userRoles, ["Partner", "Partner", "Export"])}
                    partners={BusinessPartnerData}
                    filterDate={true}
                    // isGlobalFilter={true}
                    handelAddClick={handleCustomerClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    setDateFilter={handleDateFromTo}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BusinessPartner.propTypes = {}

export default withTranslation()(BusinessPartner)
