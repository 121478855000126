import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getLogs, getUsers } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, Card, CardBody } from "reactstrap"
import { withTranslation } from "react-i18next"
import moment from "moment"

function Logs(props) {
  //meta title
  document.title = "Mazaya"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [log, setLog] = useState(null)
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    adminId: '',
    model: '',
    page: 1,
    limit: 100,
  })
  const {
    logsData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.logs)

  const { users } = useSelector(state => state.users);
  const commissions = [
    { name: "retailer", shorts: "RCOM" },
    { name: "category", shorts: "CCOM" },
    { name: "levelComission", shorts: "LCOM" },
    { name: "config", shorts: "BCOM" },
    { name: "config", shorts: "NetCOM" },
    { name: "config", shorts: "KCOM" },
    { name: "config", shorts: "MMPCOM" },
    { name: "config", shorts: "monthly_minimum_sales" },
    { name: "config", shorts: "retailer_referring_commission" },
  ]

  useEffect(() => {
    dispatch(getLogs(filter))
    dispatch(getUsers(filter))
  }, [dispatch, filter])

  //   useEffect(() => {
  //     if (!isEmpty(levels) && !!isEdit) {
  //       setIsEdit(false)
  //     }
  //   }, [levels])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setLog(null)
    } else {
      setModal(true)
    }
  }




  const compareType = (type) => {
    if (type === "retailer")
      return "RCOM"
    if (type === "category")
      return "CCOM"
    if (type === "levelComission")
      return 'LCOM'
    if (type === "buyer_commission")
      return "BCOM"
    if (type === "network_commission")
      return "NetCOM"
    if (type === "kasb_commission")
      return "KCOM"
    if (type === "monthly_minimum_purchase")
      return "MMPCOM"
    if (type === "referral_required")
      return "Referral Required"
    if (type === "monthly_minimum_sales")
      return "Monthly Minimum Sales"
    if (type === "retailer_referring_commission")
      return "Retailer Referring Commission"
    return null
  }

  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Parameter"),
        accessor: "model",
        disableFilters: true,
        accessor: cellProps => {
          return <>{compareType(cellProps.model) || compareType(cellProps.oldValues?.key)}</>
        },
      },
      {
        Header: props.t("Username"),
        accessor: "oldValues",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.oldValues?.firstname || "") + " " + (cellProps.oldValues?.lastname || "") + (cellProps.oldValues?.username ? " (" + cellProps.oldValues?.username + ") " : "")}</>
        },
      },
      {
        Header: props.t("Old value"),
        accessor: "oldvalue",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.oldValues?.comission || cellProps.oldValues?.value}</>
        },
      },
      {
        Header: props.t("New value"),
        accessor: "newvalue",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.newValues?.comission || cellProps.newValues?.value || cellProps.oldValues?.comission}</>
        },
      },
      {
        Header: props.t("Updated at"),
        accessor: "updatedAt",
        disableFilters: true,
        accessor: cellProps => {
          return <>{moment(new Date(cellProps.updatedAt)).format('YYYY-MM-DD HH:mm')}</>
        },
      },
      {
        Header: props.t("Updated from"),
        accessor: "admin",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.admin?.firstname + " " + cellProps.admin?.lastname}</>
        },
      },
    ],
    []
  )
  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }
  const handleUser = (user) => { setFilter(prevFilter => { return { ...prevFilter, adminId: user.value } }) }
  const handleParameter = (param) => { setFilter(prevFilter => { return { ...prevFilter, model: param } }) }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Logs")}
            breadcrumbItem={props.t("Logs list")}
          />
          <Row>

            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={logsData}
                    isCommissionFilter={true}
                    ResetCommissionFilter={true}
                    commissions={commissions}
                    setCommission={handleParameter}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={filter.limit}
                    setLimit={handleLimit}
                    setPageNumber={setPageNumber}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    setUser={handleUser}
                    users={users}
                    isUsersFilter={true}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Logs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Logs)
