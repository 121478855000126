import {
  GET_OFFICES_SUCCESS,
  GET_OFFICES_REQUEST,
  GET_OFFICES_FAILED,
  CREATE_OFFICE_REQUEST,
  GET_OFFICE_BY_ID_REQUEST,
  CREATE_OFFICE_SUCCESS,
  CREATE_OFFICE_FAILED,
  DELETE_OFFICE_SUCCESS,
  DELETE_OFFICE_FAILED,
  DELETE_OFFICE_REQUEST,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAILED,
  UPDATE_OFFICE_REQUEST,
  RESET_OFFICES_MESSAGES,
  RESET_OFFICE_MESSAGES_SUCCESS,
} from "./actionTypes"

export function getOffices(filter) {
  return { type: GET_OFFICES_REQUEST, payload: filter }
}

export function getOfficesSuccess(retailers) {
  return { type: GET_OFFICES_SUCCESS, payload: retailers }
}

export function getOfficesFailed(payload) {
  return { type: GET_OFFICES_FAILED, payload }
}

export function getOfficesById(name) {
  return { type: GET_OFFICE_BY_ID_REQUEST, payload: name }
}

export function createOffice(retailer) {
  return { type: CREATE_OFFICE_REQUEST, payload: retailer }
}

export function createOfficerSuccess(retailers) {
  return { type: CREATE_OFFICE_SUCCESS, payload: retailers }
}

export function createOfficeFailed(payload) {
  return { type: CREATE_OFFICE_FAILED, payload }
}

export function deleteOffice(id) {
  return { type: DELETE_OFFICE_REQUEST, payload: id }
}

export function deleteOfficeSuccess(retailers) {
  return { type: DELETE_OFFICE_SUCCESS, payload: retailers }
}

export function deleteOfficeFailed(payload) {
  return { type: DELETE_OFFICE_FAILED, payload }
}

export function updateOffice(retailer) {
  return { type: UPDATE_OFFICE_REQUEST, payload: retailer }
}

export function updateOfficeSuccess(retailers) {
  return { type: UPDATE_OFFICE_SUCCESS, payload: retailers }
}

export function updateOfficeFailed(retailers) {
  return { type: UPDATE_OFFICE_FAILED, retailers }
}

export function resetOfficesMessages() {
  return { type: RESET_OFFICES_MESSAGES }
}

export function resetOfficesMessagesSuccess() {
  return { type: RESET_OFFICE_MESSAGES_SUCCESS }
}
