import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  RESET_USERS_MESSAGES,
  UPDATE_PROFILE,
  CLEAN_USER,
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  resetUsersMessagesSuccess,
  updateUserProfilSuccess,
  updateUserProfilFail,
  cleanUser,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserProfile,
  getUsers,
  addNewUser,
  updateUser,
  getUser,
} from "../../helpers/api_helper"

function* fetchUsers({ payload }) {
  try {
    console.log("payloadAdmin", payload)
    const data = yield getUsers(payload)
    yield put(getUsersSuccess(data))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile({ payload }) {
  try {
    const response = yield getUser(payload)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const { id, ...updatedUser } = user
    const { data } = yield updateUser(id, updatedUser)
    const response = yield getUsers({
      limit: 20,
      page: 1,
      accountType: "retailer",
    })
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}
function* onUpdateProfile({ payload: profile }) {
  try {
    const { id, ...updatedProfile } = profile

    yield put(updateProfilSuccess(updatedProfile))
  } catch (error) {
    yield put(updateProfilFail(error))
  }
}

function* onDeleteUser({ payload: id }) {
  try {
    //const response = yield call(deleteUser, user) replace with api
    yield put(deleteUserSuccess(id))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* resetUsersMessagesSaga() {
  yield put(resetUsersMessagesSuccess())
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield addNewUser(user)

    // const data = yield getUsers({
    //   page: 1,
    //   limit: 100,
    //   accountType: user.accountType,
    // })

    yield put(addUserSuccess(response))
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* cleanUserSaga() {
  try {
    yield put(cleanUser())
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(RESET_USERS_MESSAGES, resetUsersMessagesSaga)
  yield takeEvery(CLEAN_USER, cleanUserSaga)
}

export default usersSaga
