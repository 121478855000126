import {
  CARD_MESSAGES_RESET,
  CREATE_CARD_FAILED,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  DELETE_CARD_FAILED,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_CARD_BY_NAME_FAILED,
  GET_CARD_BY_NAME_REQUEST,
  GET_CARD_BY_NAME_SUCCESS,
  GET_CARD_FAILED,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  UPDATE_CARD_FAILED,
  UPDATE_CARD_REQUEST,
  RESET_CARD_MESSAGES_SUCCESS,
  RESET_CARD_MESSAGES,
  UPDATE_CARD_SUCCESS,
  GET_CARD_ID_REQUEST,
  GET_CARD_ID_SUCCESS,
} from "./actionTypes.js"

export const getCardByName = name => ({
  type: GET_CARD_BY_NAME_REQUEST,
  payload: name,
})

export const getCardByNameSuccess = payload => ({
  type: GET_CARD_BY_NAME_SUCCESS,
  payload,
})

export const getCardByNameFailed = payload => ({
  type: GET_CARD_BY_NAME_FAILED,
  payload,
})

export const getCard = filter => ({
  type: GET_CARD_REQUEST,
  payload: filter,
})

export const getCardId = id => ({
  type: GET_CARD_ID_REQUEST,
  payload: id,
})

export const getCardIdSuccess = card => ({
  type: GET_CARD_ID_SUCCESS,
  payload: card,
})

export const getCardSuccess = brand => ({
  type: GET_CARD_SUCCESS,
  payload: brand,
})

export const getCardFailed = payload => ({
  type: GET_CARD_FAILED,
  payload,
})

export const createCard = brand => ({
  type: CREATE_CARD_REQUEST,
  payload: brand,
})

export const createCardSuccess = brand => ({
  type: CREATE_CARD_SUCCESS,
  payload: brand,
})

export const createCardFailed = payload => ({
  type: CREATE_CARD_FAILED,
  payload,
})

export const deleteCard = response => ({
  type: DELETE_CARD_REQUEST,
  payload: response,
})

export const deleteCardSuccess = response => ({
  type: DELETE_CARD_SUCCESS,
  payload: response,
})

export const deleteCardFailed = response => ({
  type: DELETE_CARD_FAILED,
  payload: response,
})

export const updateCard = brand => ({
  type: UPDATE_CARD_REQUEST,
  payload: brand,
})

export const updateCardSuccess = brand => ({
  type: UPDATE_CARD_SUCCESS,
  payload: brand,
})

export const updateCardFailed = payload => ({
  type: UPDATE_CARD_FAILED,
  payload,
})

export const resetCardMessages = () => ({
  type: CARD_MESSAGES_RESET,
})

export const resetCardMessagesSuccess = () => ({
  type: RESET_CARD_MESSAGES_SUCCESS,
})
