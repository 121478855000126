import {
  CREATE_SERVICE,
  CREATE_SERVICE_FAIL,
  CREATE_SERVICE_SUCCESS,
  DELETE_SERVICE,
  DELETE_SERVICE_FAIL,
  DELETE_SERVICE_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  UPDATE_SERVICE,
  UPDATE_SERVICE_FAIL,
  RESET_SERVICE_MESSAGES_SUCCESS,
  UPDATE_SERVICE_SUCCESS
} from "./actionTypes";

export const initialState = {
  loading: false,
  services: [],
  comissionIn: [],
  mmp:[],
  kasbcom:[],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        loading: true,
      }
    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_SERVICES_SUCCESS:
      const {data, ...filters} = action.payload
      return {
        ...state,
        services: [...data],
        ...filters,
        loading: false,
        success: "GET"
      }
    case CREATE_SERVICE:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        services:[...state.services, {id: state.services.length, ...action.payload}],
        loading: false,
        success: "ADD"
      }
    case CREATE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_SERVICE:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        services: state.services.map(service =>
          service.id.toString() === action.payload.id.toString()
            ? { service, ...action.payload }
            : service
        ),
        loading: false,
        success: "UPDATE"
      }
    case UPDATE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case DELETE_SERVICE:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'DELETE',
        services: state.services.filter(
          service => service.id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case RESET_SERVICE_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }


    default:
      return state;
  }
}

export default reducer;
