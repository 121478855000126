import {
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_FAIL,
  RESET_ADMINS_MESSAGES_SUCCESS,
  UPDATE_ADMIN,
  GET_ADMINS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  admins: [],
  userProfile: {},
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS:
      return {
        ...state,
        loading: true,
        success: '',
        error: ''
      }
    case GET_ADMINS_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        admins: [...data],
        loading: false,
        success: "GET",
        ...filters
      }

    case GET_ADMINS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        admins: [...state.admins, action.payload],

      }

    case ADD_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        success: "GET_PROFILE"
      }

    case UPDATE_ADMIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }

    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        success: "UPDATE",
        admins: state.admins.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
        
      }

    case UPDATE_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case UPDATE_PROFILE:
        return {
          ...state,
          loading: true,
          error: "",
          success: ""
        }
  
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          success: "UPDATE",
          userProfile:{...state.userProfile,...action.payload},
          loading: false,
        }
  
      case UPDATE_PROFILE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'DELETE',
        admins: state.admins.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ADMIN_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case RESET_ADMINS_MESSAGES_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        error: null,
        success: null
      }

    default:
      return state
  }
}

export default contacts
