import React, { useEffect, useRef, useState } from "react"
import logo from "../../../assets/images/logo.svg"

import {
  Col,
  Row,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Table,
  Button,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
import { withTranslation } from "react-i18next"
import moment from "moment"
import ReactToPrint from "react-to-print"

const PayInPrint = props => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10000,
    // status: "active",
  })
  const dispatch = useDispatch()
  const { modal, toggle, payin, isEdit } = props
  //  console.log("++++++++++++++++++++++++++++++++++",payin)
  useEffect(() => {
    // dispatch(onGetUsers(filter))
    // dispatch(getMDMPayment(filter))
  }, [dispatch, filter])
  //Print the Invoice
  const componentRef = useRef()

  return (
    <Modal
      isOpen={modal}
      style={{ minWidth: "70vw" }}
      toggle={() => toggle(false)}
      ref={componentRef}
    >
      <ModalBody>
        <Row>
          <Col lg="12" xl="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <h4 className="float-end font-size-16">Id # {payin.id}</h4>
                  <div className="mb-4">
                    <div>
                      <strong>{props.t("Payment Receipt")}</strong>
                    </div>
                    <br />
                    <img src={logo} alt="logo" height="80" />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col sm="6">
                    <address>
                      <strong>{props.t("Company Mazaya")}</strong>
                      <br />
                      <br />
                      <strong>{props.t("Company phone number :")} </strong> {""}{" "}
                      +216 53 404 503
                      <br />
                      <br />
                      <strong>{props.t("Company address :")} </strong> {""}{" "}
                      Accelerantech Sarl, Boulevard 20 Mars, Imm Aoun Jawhra
                      Sousse, 4000
                      <br />
                      <br />
                      <strong>{props.t("Email :")}</strong> {""}{" "}
                      contact.us@accelerantech.com
                      <br />
                      <br />
                      <br />
                    </address>
                  </Col>
                </Row>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 font-weight-bold">
                    <strong> Pay In</strong>
                  </h3>
                </div>
                <div className="table-responsive">
                  <Table className="table-nowrap">
                    <thead>
                      <tr>
                        <th>{props.t("Retailer Name")}</th>
                        <th>{props.t("Amount")}</th>
                        <th>{props.t("Payment Method")}</th>
                        <th>{props.t("Paiement date")}</th>
                        <th>{props.t("Payed by")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {payin.retailer?.firstname +
                            " " +
                            payin.retailer?.lastname || props.t("Not provided")}
                        </td>
                        <td>{payin.amount || props.t("----")}</td>
                        {/* <td>
                          {(payin.releasedAmount != null &&
                            payin.releasedAmount) ||
                            payin.amount ||
                            props.t("----")}
                        </td> */}
                        <td>
                          {payin.payinMethod
                            ? (currentLanguage == "ar" &&
                                payin.payinMethod?.nameAr) ||
                              (currentLanguage == "en" &&
                                payin.payinMethod?.nameEn) ||
                              (currentLanguage == "fr" &&
                                payin.payinMethod?.nameFr)
                            : props.t("Not provided")}
                        </td>
                        <td>
                          {(payin.createdAt &&
                            moment(new Date(payin.createdAt)).format(
                              "YYYY-MM-DD HH:mm"
                            )) ||
                            props.t("Not provided")}
                        </td>
                        <td>
                          {(payin.createdBy &&
                            payin.createdBy?.firstname +
                              " " +
                              payin.createdBy?.lastname) ||
                            props.t("------")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <br />
                <br />
                <br />
                <Row>
                  <Col sm="6"></Col>
                  <Col sm="2">
                    <div className="border-0 text-center">
                      <strong>{props.t("Signature")}</strong>
                    </div>
                  </Col>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Row>
                <div className="d-print-none">
                  <div className="float-end">
                    <ReactToPrint
                      trigger={() => (
                        <Button type="button" color="success">
                          Print{" "}
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PayInPrint)
