import { combineReducers } from "redux"
import { actionTypes } from "./actions"

export const initialState = {
  loading: false,
  retailersData: [],
  retailersStats: {},
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_RETAILERS_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case actionTypes.GET_RETAILERS_SUCCESS:
      const { data, ...filters } = action.payload
      console.log('---edd-----', data, filters)
      return {
        ...state,
        retailersData: data,
        ...filters,
        error: "",
        success: "GET",
        loading: false,
      }
    case actionTypes.GET_RETAILERS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case actionTypes.GET_RETAILERS_STATS_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case actionTypes.GET_RETAILERS_STATS_SUCCESS:
      
      return {
        ...state,
        retailersStats: action.payload,
        success: "GET",
        loading: false,
      }
    case actionTypes.GET_RETAILERS_STATS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case actionTypes.GET_RETAILERS_BY_ID_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case actionTypes.GET_RETAILERSBYNAME_SUCCESS:
      //const { databyName, ...filtersByname } = action.payload
      return {
        ...state,
        retailersData: state.retailersData.filter(retailer => retailer.id == action.payload),
        //...filtersByname,
        success: "GET",
        loading: false,
      }
    case actionTypes.GET_RETAILERSBYNAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }

    case actionTypes.CREATE_RETAILER_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
      }
    case actionTypes.CREATE_RETAILER_SUCCESS:
      return {
        ...state,
        retailersData: [...state.retailersData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case actionTypes.CREATE_RETAILER_FAILED:
      return {
        ...state,
        error: "CREATE",
        loading: false,
      }
    case actionTypes.DELETE_RETAILER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case actionTypes.DELETE_RETAILER_SUCCESS:
      return {
        ...state,
        retailersData: state.retailersData.filter(
          retailers => retailers.id.toString() !== action.payload.id.toString()
        ),
        success: "DELETE",
        loading: false,
      }

    case actionTypes.DELETE_RETAILER_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case actionTypes.UPDATE_RETAILER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case actionTypes.UPDATE_RETAILER_SUCCESS:
      return {
        ...state,
        retailersData: state.retailersData.map(retailer =>
          retailer.id.toString() === action.payload.id.toString()
            ? { retailer, ...action.payload }
            : retailer
        ),
        success: "UPDATE",
        loading: false,
      }
    case actionTypes.UPDATE_RETAILER_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    case actionTypes.RESET_RETAILERS_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      }
    default:
      return state
  }
}

export default reducer
