import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

import { withTranslation } from "react-i18next"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  getBusinessPartner,
  getTransactions,
  getTransactionsPartner,
  getTransactionsPartnerStats,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
// import EcommerceOrdersModal from "./EcommerceOrdersModal";

import { Col, Row, Card, CardBody } from "reactstrap"
import moment from "moment"
import { map } from "lodash"
import MiniCards from "pages/Dashboard/mini-card"

function PartnerTransactionsIncome(props) {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",
    userId: "",
    commissionType: "",
    from: "",
    to: "",
    page: 1,
    limit: "100",
  })

  const dispatch = useDispatch()

  const {
    partnerTransactionsData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.transactions)
  const { partnerTransactionsStats } = useSelector(state => state.transactions)
  const { BusinessPartnerData } = useSelector(state => state.businesspartners)
  const [miniCards, setMiniCards] = useState([

    {
      title: props.t("Total Buyer Commission"),
      iconClass: "bx bx-dollar",
      text: 0,
    },
    {
      title: props.t("Total Mazaya Commission"),
      iconClass: "bx bx-dollar",
      text: 0,
    },
    {
      title: props.t("Total Level Commission"),
      iconClass: "bx bx-dollar",
      text: 0,
    },
    {
      title: props.t("Total Missing Level Commission"),
      iconClass: "bx bx-dollar",
      text: 0,
    },

  ])
  useEffect(() => {
    if (partnerTransactionsData != undefined) {
      setMiniCards([
        {
          title: props.t("Total Buyer Commission"),
          iconClass: "bx bx-dollar",
          text: partnerTransactionsStats?.totalBuyerCom,
        },
        {
          title: props.t("Total Mazaya Commission"),
          iconClass: "bx bx-dollar",
          text: partnerTransactionsStats?.totalMazayaCom,
        },
        {
          title: props.t("Total Level Commission"),
          iconClass: "bx bx-dollar",
          text: partnerTransactionsStats?.totalLevelComission,
        },
        {
          title: props.t("Total Missing Level Commission"),
          iconClass: "bx bx-dollar",
          text: partnerTransactionsStats?.totalMLCOM,
        },
      ]
      )
    }
  }, [partnerTransactionsStats])
  // console.log("partnerTransactionsStats",partnerTransactionsStats)
  useEffect(() => {
    dispatch(getTransactionsPartner(filter))
    dispatch(getBusinessPartner(filter))
    dispatch(getTransactionsPartnerStats())
  }, [dispatch, filter])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Reference"),
        accessor: "transaction",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>{cellProps.transaction?.reference || props.t("Not provided")}</>
          )
        },
      },
      {
        Header: props.t("Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD") ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Retailer"),
        accessor: "transaction",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.transaction?.retailer?.firstname +
                " " +
                cellProps.transaction?.retailer?.lastname ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Buisness Partner"),
        accessor: "transaction",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.partner &&
                cellProps.partner?.firstname +
                " " +
                cellProps.partner?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Amount"),
        accessor: "amount",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.amount || props.t("Not provided")}</>
        },
      },

      {
        Header: props.t("Commission Type"),
        accessor: "commissionType",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.commissionType || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.status || props.t("Not provided")}</>
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const setCommissionType = commissionType => {
    setFilter(prevFilter => {
      return { ...prevFilter, commissionType: commissionType }
    })
  }
  const handlePartner = partner => {
    setFilter(prevFilter => {
      return { ...prevFilter, partnerId: partner.value }
    })
  }

  const setDateFilter = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Transactions")}
            breadcrumbItem={props.t("Partner Transactions Income")}
          />
          <Row>
            {map(miniCards, (card, key) => (
              <Col xl="3" xs="12">
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
              </Col>
            ))}
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={partnerTransactionsData}
                    // isGlobalFilter={true}
                    resetPartnerTransactionOption={true}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    isPartnersFilter={true}
                    setPartner={handlePartner}
                    partners={BusinessPartnerData}
                    filterDate={true}
                    commissionTypeFilter={true}
                    setCommission={setCommissionType}
                    setDateFilter={setDateFilter}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PartnerTransactionsIncome.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(PartnerTransactionsIncome)
