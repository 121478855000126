/**filter */
export const GET_CARD_BY_NAME_SUCCESS = "GET_CARD_BY_NAME_SUCCESS"
export const GET_CARD_BY_NAME_REQUEST = "GET_CARD_BY_NAME_REQUEST"
export const GET_CARD_BY_NAME_FAILED = "GET_CARD_BY_NAME_FAILED"

/**Get */
export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS"
export const GET_CARD_REQUEST = "GET_CARD_REQUEST"
export const GET_FLAT_REQUEST = "GET_FLAT_REQUEST"
export const GET_FLAT_SUCCESS = "GET_FLAT_SUCCESS"
export const GET_CARD_FAILED = "GET_CARD_FAILED"

export const GET_CARD_ID_SUCCESS = "GET_CARD_ID_SUCCESS"
export const GET_CARD_ID_REQUEST = "GET_CARD_ID_REQUEST"

/**Create */
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS"
export const CREATE_CARD_FAILED = "CREATE_CARD_FAILED"
export const CREATE_CARD_REQUEST = "CREATE_CARD_REQUEST"
/**Delete */
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED"
export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST"
/**Update */
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS"
export const UPDATE_CARD_FAILED = "UPDATE_CARD_FAILED"
export const UPDATE_CARD_REQUEST = "UPDATE_CARD_REQUEST"

export const CARD_MESSAGES_RESET = "CARD_MESSAGES_RESET"
export const CARD_MESSAGES_SUCCESS = "CARD_MESSAGES_SUCCESS"
export const RESET_CARD_MESSAGES_SUCCESS = "RESET_CARD_MESSAGES_SUCCESS"
export const RESET_CARD_MESSAGES = "RESET_CARD_MESSAGES"
