import React, { useEffect, useRef, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { useSelector, useDispatch } from "react-redux"
import {
  createCardType,
  getMDMParrainage,
  updateCardType,
  getCardType,
} from "store/actions"
import { withTranslation } from "react-i18next"
import { upload } from "helpers/api_helper"

const PaymentModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, cardType, isEdit } = props

  const [loading, setLoading] = useState(false)
  const fileRef = useRef()
  const { parrainageData } = useSelector(state => state.parrainageTypes)

  useEffect(() => {
    dispatch(getMDMParrainage({ page: 1, limit: 1000, status: "active" }))
  }, [dispatch])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (cardType && cardType.id) || "",
      name: (cardType && cardType.name) || "",
      price_TND: (cardType && cardType.price_TND) || 0,
      price_DZD: (cardType && cardType.price_DZD) || 0,
      price_LYD: (cardType && cardType.price_LYD) || 0,
      imageUrl: (cardType && cardType.imageUrl) || "",
      nbParrainage: (cardType && cardType.nbParrainage) || 0,
      status: (cardType && cardType.status) || "active",
      // Ensure description is initialized as an array
      description:
        cardType &&
        Array.isArray(cardType.description) &&
        cardType.description.length > 0
          ? cardType.description
          : [""],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please enter name")),
      price_TND: Yup.string().required(props.t("Please Enter price")),
      price_DZD: Yup.string().required(props.t("Please Enter price")),
      price_LYD: Yup.string().required(props.t("Please Enter price")),
      nbParrainage: Yup.string().required(
        props.t("Please Enter select parrainage")
      ),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedCardType = {
          id: cardType.id,
          name: values.name,
          price_TND: values.price_TND,
          price_DZD: values.price_DZD,
          price_LYD: values.price_LYD,
          nbParrainage: values.nbParrainage,
          imageUrl: values.imageUrl,
          description: values.description,
          status: values.status,
        }
        // update order
        dispatch(updateCardType(updatedCardType))
        // console.log("updated cardType methode", updatedPayment)
        validation.resetForm()
      } else {
        const newCardType = {
          name: values["name"],
          description: values["description"],
          nbParrainage: values["nbParrainage"],
          status: values["status"],
          price_TND: parseInt(values["price_TND"]),
          price_DZD: parseInt(values["price_DZD"]),
          price_LYD: parseInt(values["price_LYD"]),
          imageUrl: values["imageUrl"],
        }
        // console.log("newPaymentMethod", newPayment)

        // save new method-cardType
        dispatch(createCardType(newCardType))
        dispatch(getCardType({ page: 1, limit: 100, status: "active" }))

        validation.resetForm()
      }
      toggle()
    },
  })

  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("imageUrl", path)
      })
      .catch(e => {
        setLoading(false)
        console.log(e.response)
      })
  }

  const handleFileUpload = e => {
    e.preventDefault()
    fileRef?.current?.click()
  }

  const resetFileHandler = e => {
    e.preventDefault()
    validation.setFieldValue("imageUrl", "")
  }

  const onChangeParriange = event => {
    const nbParrainage =
      parrainageData?.find(item => item.id === parseInt(event.target.value))
        ?.value || 0
    validation.setFieldValue("nbParrainage", nbParrainage)
  }

  const addDescription = () => {
    validation.setFieldValue("description", [
      ...validation.values.description,
      "",
    ])
  }

  const handleDescriptionChange = (index, event) => {
    const newDescriptions = [...validation.values.description]
    newDescriptions[index] = event.target.value
    validation.setFieldValue("description", newDescriptions)
  }

  const removeDescription = index => {
    const newDescriptions = validation.values.description.filter(
      (_, i) => i !== index
    )
    validation.setFieldValue("description", newDescriptions)
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit
          ? props.t("Edit Parrainage Type")
          : props.t("Add Parrainage Type")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Image")}</Label>
                <div className="row">
                  <div className="d-flex justify-content-center mb-3">
                    {validation.values.imageUrl ? (
                      <img
                        className=" avatar-lg"
                        src={validation.values.imageUrl}
                        alt=""
                        style={{ width: "200px", height: "100px" }}
                      />
                    ) : (
                      <div className="avatar-lg d-flex justify-content-center align-items-center">
                        <span className="avatar-title rounded-circle">
                          {validation.values.name?.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="position-relative">
                      <button
                        className="btn btn-light w-100"
                        onClick={handleFileUpload}
                      >
                        {!loading &&
                          !validation.values.imageUrl &&
                          props.t("Upload Image")}
                        {loading && (
                          <Spinner className="text-center mt-1" size="sm" />
                        )}
                        {!loading &&
                          validation.values?.imageUrl?.split("/")[
                            validation.values?.imageUrl?.split("/").length - 1
                          ]}
                      </button>
                      <span
                        className="btn btn-info btn-icon sketch-picker ms-n4 mt-n2 rounded-circle"
                        onClick={resetFileHandler}
                      >
                        <i className="bx bx-x bx-xs"></i>
                      </span>
                      <Input
                        innerRef={fileRef}
                        name="imageUrl"
                        type="file"
                        hidden
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={convertToBlob(validation.values.imageUrl)}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {/* {!loading && validation.values.imageUrl && isEdit && */}
                      {/* (<i
                        className="bx bx-x bx-sm float-end me-3"
                        //type="button"
                        onClick={onRemove}
                      />) */}
                      {/* } */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Price TND")}</Label>
                <Input
                  name="price_TND"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price_TND || ""}
                  invalid={
                    validation.touched.price_TND && validation.errors.price_TND
                      ? true
                      : false
                  }
                />
                {validation.touched.price_TND && validation.errors.price_TND ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price_TND}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Price DZD")}</Label>
                <Input
                  name="price_DZD"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price_DZD || ""}
                  invalid={
                    validation.touched.price_DZD && validation.errors.price_DZD
                      ? true
                      : false
                  }
                />
                {validation.touched.price_DZD && validation.errors.price_DZD ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price_DZD}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Price LYD")}</Label>
                <Input
                  name="price_LYD"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price_LYD || ""}
                  invalid={
                    validation.touched.price_LYD && validation.errors.price_LYD
                      ? true
                      : false
                  }
                />
                {validation.touched.price_LYD && validation.errors.price_LYD ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price_LYD}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Parrainage")}</Label>
                <Input type="select" onChange={onChangeParriange}>
                  <option key="empty" disabled></option>
                  {parrainageData?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nameEn || "---"}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("N. parrainage")}</Label>
                <Input
                  name="nbParrainage"
                  type="number"
                  disabled
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nbParrainage || ""}
                  invalid={
                    validation.touched.nbParrainage &&
                    validation.errors.nbParrainage
                      ? true
                      : false
                  }
                />
                {validation.touched.nbParrainage &&
                validation.errors.nbParrainage ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nbParrainage}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Descriptions")}</Label>
                {validation.values.description.map((desc, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-2 space-x-10"
                  >
                    <Input
                      name={`description_${index}`}
                      type="text"
                      value={desc}
                      onChange={e => handleDescriptionChange(index, e)}
                      invalid={
                        validation.touched.description &&
                        validation.errors.description
                          ? true
                          : false
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeDescription(index)}
                    >
                      {props.t("Remove")}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addDescription}
                >
                  {props.t("Add Description")}
                </button>
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">{props.t("Status")}</Label>
                <div className="form-check form-switch mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    //style={{borderColor: '#34c38f'}}
                    name="status"
                    onClick={e => {
                      validation.setFieldValue(
                        "status",
                        !validation.values.status
                      )
                    }}
                    onBlur={validation.handleBlur}
                    checked={validation.values?.status || false}
                    onInvalid={
                      validation.touched.status && validation.errors.status
                        ? true
                        : false
                    }
                  />
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type="invalid">
                      {validation.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PaymentModal)
