import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

import { withTranslation } from "react-i18next"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  getBusinessPartner,
  getRetailers,
  getTransactionsIncome,
  getTransactionsIncomeStats,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, Card, CardBody } from "reactstrap"
import moment from "moment"
import { map } from "lodash"
import MiniCards from "pages/Dashboard/mini-cards"

function TransactionIncom(props) {
  //meta title
  document.title = "Mazaya"

  const [filter, setFilter] = useState({
    partnerId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })

  const dispatch = useDispatch()
  const { transactionIncomeData, success, error, nextPage, previousPage, currentPage, total, limit, loading } = useSelector(state => state.transactions)
  const { transactionIncomeStats } = useSelector(state => state.transactions)

  const { BusinessPartnerData } = useSelector(state => state.businesspartners)
  const { retailersData } = useSelector(state => state.retailers)

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total Commission"),
      iconClass: "bx bx-dollar",
      text: 0,
    },
    {
      title: props.t("Total Transactions Amounts"),
      iconClass: "bx bx-dollar",
      text: 0,
    },
  ])

  
  useEffect(() => {
    dispatch(getTransactionsIncome(filter))
    dispatch(getBusinessPartner({ limit: 10000, page: 1 }))
    dispatch(getRetailers({ limit: 10000, page: 1 }))
    dispatch(getTransactionsIncomeStats())
  }, [dispatch, filter])

  useEffect(() => {
    if(transactionIncomeData != undefined){
      setMiniCards([
        {
          title: props.t("Total Commission"),
          iconClass: "bx bx-dollar",
          text: transactionIncomeStats?.totalComissions,
        },
        {
          title: props.t("Total Transactions Amounts"),
          iconClass: "bx bx-dollar",
          text: transactionIncomeStats?.totalTransactionsAmounts,
        },
      ]
      )
    }
  }, [transactionIncomeStats])


  const columns = useMemo(
    () => [
      {
        Header: props.t("Reference"),
        accessor: "reference",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>{cellProps.transaction?.reference || props.t("Not provided")}</>
          )
        },
      },
      {
        Header: props.t("Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD") ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Amount"),
        accessor: "amount",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.amount || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Retailer Commission %"),
        accessor: "retailerCommissionValue",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>{cellProps.retailerCommissionValue || props.t("Not provided")}</>
          )
        },
      },
      {
        Header: props.t("Buyer commission"),
        accessor: "buyerCom",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.buyerCom}</>
        },
      },
      {
        Header: props.t("Mazaya commission"),
        accessor: "kasbCom",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.kasbCom}</>
        },
      },
      {
        Header: props.t("Network commission"),
        accessor: "networkCom",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.networkCom}</>
        },
      },
      {
        Header: props.t("Retailer"),
        accessor: "retailer",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.transaction?.retailer?.firstname +
                " " +
                cellProps.transaction?.retailer?.lastname}
            </>
          )
        },
      },

      {
        Header: props.t("Buisness Partner"),
        accessor: "partner",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.transaction?.partner?.firstname +
                " " +
                cellProps.transaction?.partner?.lastname ||
                cellProps.transaction?.partner?.username}
            </>
          )
        },
      },

      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.transaction?.status}</>
        },
      },
    ],
    []
  )
  

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handlePartner = partner => {
    setFilter(prevFilter => {
      return { ...prevFilter, partnerId: partner.value }
    })
  }
  const handleRetailer = retailer => {
    setFilter(prevFilter => {
      return { ...prevFilter, retailerId: retailer.value }
    })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Transactions")}
            breadcrumbItem={props.t("Transactions Incomes")}
          />
          <Row>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={transactionIncomeData}
                    resetTransactionIncomeOption={true}
                    setPartner={handlePartner}
                    partners={BusinessPartnerData}
                    isPartnersFilter={true}
                    setRetailer={handleRetailer}
                    retailers={retailersData}
                    isRetailerTransactionFilter={true}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
TransactionIncom.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(TransactionIncom)
