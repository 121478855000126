/* ADMINS */
export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL"

/* ADMINS PROFILE */
export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE"
export const GET_ADMIN_PROFILE_SUCCESS = "GET_ADMIN_PROFILE_SUCCESS"
export const GET_ADMIN_PROFILE_FAIL = "GET_ADMIN_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_ADMIN = "ADD_NEW_ADMIN"
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL"

/**
 * Edit user
 */
export const UPDATE_ADMIN = "UPDATE_ADMIN"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS"
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL"

/**
 * Delete user
 */
export const DELETE_ADMIN = "DELETE_ADMIN"
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS"
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL"

/** Reset Messages */
export const RESET_ADMINS_MESSAGES = "RESET_ADMINS_MESSAGES"
export const RESET_ADMINS_MESSAGES_SUCCESS = "RESET_ADMINS_MESSAGES_SUCCESS"
