import React, { useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
//redux
import {  useDispatch, useSelector } from "react-redux"

import "react-datepicker/dist/react-datepicker.css"

import "assets/scss/datatables.scss"
import { map } from "lodash"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import {
  getBusinessPartner,
  getEwallet,
  getEwalletStats,
  getRetailers,
} from "store/actions"
import EwalletTransaction from "./EwalletTransaction"
import EwalletBalance from "./EwalletBalance"
import EwalletStatement from "./EwalletStatement"
import UserEarnings from "./UserEarnings"
import { checkPermission } from "helpers/functions"

const Ewallet = props => {

  //meta title
  document.title = "Mazaya"


 

  const [filter, setFilter] = useState({
    partnerId: "",
    adminId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 20,
  })
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()
  const {
    ewalletData,
    error,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.ewallets)
  const {ewalletStats} = useSelector(state => state.ewallets)
  const userRoles = useSelector(state => state.Login.user?.roles)

  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getRetailers(filter))
    dispatch(getEwallet())
    dispatch(getEwalletStats())
  }, [dispatch, filter])
  
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [miniCards, setMiniCards] = useState([
   
    {
      title: props.t("E-wallet Balance Retailers"),
      iconClass: "bx bxs-briefcase",
      text: 0,
      color: "success",
    },
    {
      title: props.t("E-wallet  Balance Partners"),
      iconClass:"bx bxs-briefcase",
      text:  0,
      color: "success",
    },
  ])
 
  useEffect(() => {
    if(ewalletData != undefined){
      setMiniCards([
        {
          title: props.t("E-wallet Balance Retailers"),
          iconClass: "bx bxs-briefcase",
          text: ewalletStats?.totalRetailer,
          color: "success",
        },
        {
          title: props.t("E-wallet  Balance Partners"),
          iconClass:"bx bxs-briefcase",
          text:  ewalletStats?.totalPartner,
          color: "success",
        },
      ]
      )
    }
  }, [ewalletStats])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Ewallet")}
            breadcrumbItem={props.t("Ewallet")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    color={card.color}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    {checkPermission(userRoles, ["Ewallet", "Transaction", "List"]) &&  
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("E-wallet-Transactions")}
                      </NavLink>
                    </NavItem>}
                   {checkPermission(userRoles, ["Ewallet", "Balance", "List"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {props.t("E-wallet Balance")}
                      </NavLink>
                    </NavItem>}
                    {checkPermission(userRoles, ["Ewallet", "Statement", "List"]) && 
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        {props.t("E-wallet Statement")}
                      </NavLink>
                    </NavItem>}
                    {checkPermission(userRoles, ["Ewallet", "UserEarning", "List"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        {props.t("User earnings")}
                      </NavLink>
                    </NavItem>}
                  </ul>

                  <TabContent activeTab={activeTab} className="p-4">
                  <EwalletTransaction/>
                   <EwalletBalance/>
                    <EwalletStatement/>
                   <UserEarnings/>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Ewallet.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Ewallet)
