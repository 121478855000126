import LibFlag from "../assets/images/flags/Libya.jpg"
import DzFLag from "../assets/images/flags/Algeria.jpg"
import tnFlag from "../assets/images/flags/tunisia.png"

const currencies = {
  TND: {
    label: "د.ت",
    flag: tnFlag,
  },
  DZD: {
    label: "د.ل",
    flag: DzFLag,
  },
  LYD: {
    label: "د.ج",
    flag: LibFlag,
  },
}

export default currencies
