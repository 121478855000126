import { combineReducers } from "redux"
import {
  GET_OFFICES_SUCCESS,
  GET_OFFICES_REQUEST,
  GET_OFFICES_FAILED,
  CREATE_OFFICE_REQUEST,
  GET_OFFICE_BY_ID_REQUEST,
  CREATE_OFFICE_SUCCESS,
  CREATE_OFFICE_FAILED,
  DELETE_OFFICE_SUCCESS,
  DELETE_OFFICE_FAILED,
  DELETE_OFFICE_REQUEST,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAILED,
  UPDATE_OFFICE_REQUEST,
  RESET_OFFICES_MESSAGES,
  RESET_OFFICE_MESSAGES_SUCCESS,
} from "./actionTypes"

export const initialState = {
  loading: false,
  officesData: [],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFICES_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_OFFICES_SUCCESS:
      const { data, ...filters } = action.payload
      console.log("---edd-----", data, filters)
      return {
        ...state,
        officesData: data,
        ...filters,
        error: "",
        success: "GET",
        loading: false,
      }
    case GET_OFFICES_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }

    case GET_OFFICE_BY_ID_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }

    case CREATE_OFFICE_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
      }
    case CREATE_OFFICE_SUCCESS:
      return {
        ...state,
        officesData: [...state.officesData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case CREATE_OFFICE_FAILED:
      return {
        ...state,
        error: "CREATE",
        loading: false,
      }
    case DELETE_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case DELETE_OFFICE_SUCCESS:
      return {
        ...state,
        officesData: state.officesData.filter(
          retailers => retailers.id.toString() !== action.payload.id.toString()
        ),
        success: "DELETE",
        loading: false,
      }

    case DELETE_OFFICE_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case UPDATE_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case UPDATE_OFFICE_SUCCESS:
      return {
        ...state,
        officesData: state.officesData.map(retailer =>
          retailer.id.toString() === action.payload.id.toString()
            ? { retailer, ...action.payload }
            : retailer
        ),
        success: "UPDATE",
        loading: false,
      }
    case UPDATE_OFFICE_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    case RESET_OFFICE_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      }
    default:
      return state
  }
}

export default reducer
