import {
  PAYMENT_MESSAGES_RESET,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILED,
  DELETE_PAYMENT_REQUEST,
  DELETE_PAYMENT_SUCCESS,
  GET_PAYMENT_BY_NAME_FAILED,
  GET_PAYMENT_BY_NAME_REQUEST,
  GET_PAYMENT_BY_NAME_SUCCESS,
  GET_PAYMENT_FAILED,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILED,
  UPDATE_PAYMENT_REQUEST,
  RESET_PAYMENT_MESSAGES,
  RESET_PAYMENT_MESSAGES_SUCCESS,
  UPDATE_PAYMENT_SUCCESS,
  DELETE_DEFAULT_PAYMENT_REQUEST,
  DELETE_DEFAULT_PAYMENT_SUCCESS,
  DELETE_DEFAULT_PAYMENT_FAILED,
  MAKE_DEFAULT_PAYMENT_REQUEST,
  MAKE_DEFAULT_PAYMENT_SUCCESS,
  MAKE_DEFAULT_PAYMENT_FAILED,
} from "./actionTypes.js"

export const getPaymentByName = name => ({
  type: GET_PAYMENT_BY_NAME_REQUEST,
  payload: name,
})

export const getPaymentByNameSuccess = payload => ({
  type: GET_PAYMENT_BY_NAME_SUCCESS,
  payload,
})

export const getPaymentByNameFailed = payload => ({
  type: GET_PAYMENT_BY_NAME_FAILED,
  payload,
})

export const getMDMPayment = filter => ({
  type: GET_PAYMENT_REQUEST,
  payload: filter,
})

export const getMDMPaymentSuccess = payment => ({
  type: GET_PAYMENT_SUCCESS,
  payload: payment,
})

export const getMDMPaymentFailed = payload => ({
  type: GET_PAYMENT_FAILED,
  payload,
})

export const createMDMPayment = Payment => ({
  type: CREATE_PAYMENT_REQUEST,
  payload: Payment,
})

export const createMDMPaymentSuccess = Payment => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload: Payment,
})

export const createMDMPaymentFailed = payload => ({
  type: CREATE_PAYMENT_FAILED,
  payload,
})

export const deleteMDMPayment = id => ({
  type: DELETE_PAYMENT_REQUEST,
  payload: id,
})

export const deleteMDMPaymentSuccess = id => ({
  type: DELETE_PAYMENT_SUCCESS,
  payload: id,
})

export const deleteMDMPaymentFailed = id => ({
  type: DELETE_PAYMENT_FAILED,
  payload: id,
})

export const updateMDMPayment = Payment => ({
  type: UPDATE_PAYMENT_REQUEST,
  payload: Payment,
})

export const updateMDMPaymentSuccess = Payment => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: Payment,
})

export const updateMDMPaymentFailed = payload => ({
  type: UPDATE_PAYMENT_FAILED,
  payload,
})

export const resetMDMPaymentMessages = () => ({
  type: PAYMENT_MESSAGES_RESET,
})

export const resetPaymentMessages = () => ({
  type: RESET_PAYMENT_MESSAGES,
})

export const resetPaymentMessagesSuccess = () => ({
  type: RESET_PAYMENT_MESSAGES_SUCCESS,
})

export const makeDefaultPayment = id => ({
  type: MAKE_DEFAULT_PAYMENT_REQUEST,
  payload: id,
})

export const makeDefaultPaymentSuccess = id => ({
  type: MAKE_DEFAULT_PAYMENT_SUCCESS,
  payload: id,
})

export const makeDefaultPaymentFailed = id => ({
  type: MAKE_DEFAULT_PAYMENT_FAILED,
  payload: id,
})
